import Inter from "../inter";
import styled, { keyframes } from "styled-components";
import SpaceGrotesk from "../../components/space-grotesk";

type ApplicationProps = {
  isMobile?: boolean | null;
};

type ButtonsProps = {
  last: boolean;
  page: number;
};
type ProgressProps = {
  value: number;
};
type ShowProps = {
  show?: boolean;
};

const StyledH2 = styled(SpaceGrotesk)<ApplicationProps>`
  text-shadow: none;
  font-size: ${(props) => (props.isMobile ? "24px" : "48px")};
  letter-spacing: ${(props) => (props.isMobile ? "-1px" : "-2.4px")};
  margin-top: ${(props) => (props.isMobile ? "0" : "30px")};
  margin-bottom: 20px;
  color: var(--Default-Stroke, #225c90);
`;

const LoginForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApplicationPage = styled.div<ShowProps>`
  display: ${(props) => (props.show ? "block" : "none")};
  width: 70%;
  margin-bottom: 30px;
`;

const NameInput = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    width: 48%;
  }
`;

const ErrorBox = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 5px;
  color: #cb3101;
  background-color: #fdecea;
`;

const ErrorText = styled.span`
  height: 100%;
  display: inline;
  margin: auto 10px;
`;

const Buttons = styled.div<ButtonsProps>`
  width: 90%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  button:first-of-type {
    margin-right: 20px;
  }
`;

const ProgressContainer = styled.div`
  margin-top: 2rem;
  width: 90%;
  height: 20px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--Default-Stroke, #0daef1);
  background-color: #ffffff;
`;

const Filling = styled.div<ProgressProps>`
  height: 100%;
  width: ${({ value }) => value * 100}%;
  background: var(--Default-Stroke, #0daef1);
  transition: width 0.3s ease;
  display: flex;
  justify-content: flex-end;
`;

const StyledImage = styled.img<ProgressProps>`
  ${({ value }) =>
    value === 1
      ? `
      
      margin-right: -0.5rem;
      
      `
      : value === 0
      ? `
      margin-right: -1.5rem;
      
      `
      : `
      margin-left: 1rem;
      margin-right: -1.1rem;
      
    `}
  height: 140%;
  margin-top: -0.45rem;

  object-fit: contain;
  width: 35px;
  height: 35px;
  object-position: center;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const ProgressText = styled(Inter)`
  color: #225c90;
  text-align: center;
  leading-trim: both;

  text-edge: cap;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1.63rem;
`;

const StyledInter = styled(Inter)`
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  color: var(--Default-Stroke, #007d7d);
`;

const StyledLink = styled.a`
  color: #007d7d;

  text-align: right;
  leading-trim: both;

  text-edge: cap;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
`;

const RequiredStarStyle = styled.span`
  color: #cb3101;
  margin-left: 0.2rem;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

const HackathonInputContainer = styled(Inter)<ShowProps>`
  display: ${(props) => (props.show ? "block" : "none")};
  font-size: 14px;
  animation: ${fadeIn} 1s ease-in-out;
  background-color: transparent;
`;

const MainContainer = styled.div`
  animation: ${fadeIn} 0.7s ease-in-out;
`;

const FlexContainer = styled.div<{ isMobile?: boolean | null }>`
  display: ${({ isMobile }) => (isMobile ? 'block' : 'flex')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '0' : '5px')};
  width: 100%;
  max-width: 1000px;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-around' : 'space-around')};
  align-items: center;

  & > * {
    flex: ${({ isMobile }) => (isMobile ? '1 1 40%' : '1 1 40%')};
  }
`;


export {
  StyledH2,
  LoginForm,
  ApplicationPage,
  NameInput,
  ErrorBox,
  ErrorText,
  Buttons,
  ProgressContainer,
  Filling,
  ProgressText,
  StyledImage,
  StyledInter,
  StyledLink,
  RequiredStarStyle,
  HackathonInputContainer,
  MainContainer,
  FlexContainer,
};
