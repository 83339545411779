import styled, { keyframes } from "styled-components";
import SpaceGrotesk from "../../space-grotesk";
import Manrope from "../../manrope";

type HomePageContentProps = {
  isDrawerOpen?: boolean;
  mobile?: boolean | null;
};

const StyledSpaceGrotesk = styled(SpaceGrotesk)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
  font-size: ${({ mobile }) => (mobile ? "36px" : "48px")};
  display: flex;
  justify-answer: flex-start;
  align-items: left;
  text-align: left;
`;

const StyledManropeSemiBold = styled(Manrope)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  text-align: left;
  font-size: ${(props) => (props.mobile ? "16px" : "20px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
`;

const StyledManrope = styled(Manrope)<HomePageContentProps>`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-size: ${(props) => (props.mobile ? "12px" : "14px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3rem;
  margin-top: 10px;
`;

const StyledImage = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 20px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1; 
  }
`;

const MainContainer = styled.div`
  animation: ${fadeIn} 0.7s ease-in-out;
`;

export {
  StyledSpaceGrotesk,
  StyledManrope,
  StyledManropeSemiBold,
  StyledImage,
  MainContainer,
};
