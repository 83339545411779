import React from "react";
import DashboardContainer from "../dashboard-container";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import Link from "../link";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  DashboardContents,
  StyledH2,
  StyledInter,
  StyledSVG,
  TextWrapper,
} from "./index.styles";
const ErrorPageComponent = () => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state: RootState) => state.data);
  return (
    <DashboardContainer>
      <DashboardContents>
        <StyledH2 isMobile={isMobile}>Oops! Something went wrong.</StyledH2>
        <TextWrapper isMobile={isMobile}>
          <StyledInter>
            Please Try Again. If this problem persists, please reach out to 
            <a href="mailto:contact@uofthacks.ca" style={{ textDecoration: "none", color: "inherit" }}> contact@uofthacks.ca</a>
          </StyledInter>
        </TextWrapper>
        <Button
          text={"BACK TO " + (isAuthenticated ? "PORTAL" : "LOGIN")}
          colour="blue"
          onClick={() => {
            isAuthenticated ? navigate("/") : navigate("/login");
          }}
        />
      </DashboardContents>
    </DashboardContainer>
  );
};

export default ErrorPageComponent;
