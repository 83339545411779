import React from "react";
import {
  ProgressContainer,
  Filling,
  ProgressText,
  StyledImage,
} from "./index.styles";

const Progress = React.memo(
  ({ value, in_app }: { value: number; in_app: boolean }) => {
    return (
      <>
        <ProgressContainer style={{ display: in_app ? "block" : "none" }}>
          <Filling value={value}></Filling>
        </ProgressContainer>
        {value === 1 ? (
          <ProgressText style={{ display: in_app ? "block" : "none" }}>
            {" "}
            Yayy! You've completed your application 🎉🥳
          </ProgressText>
        ) : (
          <ProgressText style={{ display: in_app ? "block" : "none" }}>
            {" "}
            {Math.floor(value * 100)}% COMPLETE
          </ProgressText>
        )}
      </>
    );
  }
);

export default Progress;
