import React, { useState } from "react";
import Input from "../input-box";
import PasswordInput from "../password-input-box";
import Button from "../button";
import Link from "../link";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import DashboardContainer from "../dashboard-container";
import {
  StyledH2,
  LoginForm,
  LoginInfo,
  NameInput,
  ErrorBox,
  ErrorText,
} from "./index.styles";
import LoadingCircleComponent from "../loading-component";
import { API_BASE_URL, API_TYPE, API_VERSION } from "../../api/apiConfig";
import ReCAPTCHA from "react-google-recaptcha";
import {CLOSED_TESTING_REGISTER} from "../../utils/featureFlags";
const CLOSED_TESTING_TOKEN = 'UofTH4cks12$!'
const CreateAccountComponent = React.memo(({ onSuccess }: any) => {
  const isMobile = useMobileDetect();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isFirstNameError, setFirstNameError] = useState(false);
  const [isLastNameError, setLastNameError] = useState(false);
  const [isEmailAddressError, setEmailAddressError] = useState(false);
  const [isPasswordError, setPasswordError] = useState(false);
  const [isPasswordConfirmError, setPasswordConfirmError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [authErrorText, setAuthErrorText] = useState("Error");
  const [isLoading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [isSecretCodeError, setSecretCodeError] = useState(false)

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#?&])[A-Za-z\d@$!%#?&]{8,}$/;

  const emailRegex =
    /^[a-zA-Z0-9._%+-]+@(?!.*\.{2})[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;

  const handleCreateAccount = () => {
    setSecretCodeError(false)
    if (CLOSED_TESTING_REGISTER && secretCode !== CLOSED_TESTING_TOKEN) {
      setSecretCodeError(true)
      return
    }
    if (
      emailAddress &&
      emailRegex.test(emailAddress) &&
      password &&
      firstName &&
      lastName &&
      password === passwordConfirm &&
      passwordRegex.test(password)
    ) {
      setAuthError(false);
      setFirstNameError(false);
      setLastNameError(false);
      setEmailAddressError(false);
      setPasswordError(false);
      setPasswordConfirmError(false);
      setAuthErrorText("");
      setIsLoading(true);
      fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hacker_email: emailAddress,
          hacker_password: password,
          hacker_fname: firstName,
          hacker_lname: lastName,
          hacker_password_cmp: passwordConfirm,
          captchaToken: captchaToken,
        }),
      })
        .then((response) => {
          if (response.ok) {
            // Send verification email
            setIsLoading(true);
            fetch(
              `${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/verify_email`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  hacker_email: emailAddress,
                  hacker_fname: firstName,
                }),
              }
            )
              .then(() => {
                setIsLoading(false);
                console.log("API call successful, calling onSuccess");
                onSuccess();
              })

              .catch((error) => {
                setAuthError(true);
                setAuthErrorText(
                  "Something doesn't look right on our end. Please try again later."
                );
                console.error(error);
                setIsLoading(false);
              });
          } else {
            setAuthError(true);
            response.json().then((data) => {
              if (data.message.includes("Captcha failed.")) {
                setAuthErrorText("Captcha failed. Please try again!");
              } else if (data.detail && data.detail.text) {
                if (data.detail.text.includes("Duplicate entry")) {
                  setAuthErrorText(
                    "An account under this email already exists. If you have an account, please log in."
                  );
                } else {
                  setAuthErrorText(
                    "Please make sure you have filled out all fields correctly.\n" +
                      data.detail.text
                  );
                }
              } else {
                setAuthErrorText(
                  "Something doesn't look right on our end. Please try again later."
                );
              }
            });
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setAuthError(true);
          setAuthErrorText(
            "Something doesn't look right on our end. Please try again later."
          );
          console.error(error);
          setIsLoading(false);
        });
    } else {
      if (!emailAddress || !emailRegex.test(emailAddress))
        setEmailAddressError(true);
      else setEmailAddressError(false);
      if (!password || !passwordRegex.test(password)) setPasswordError(true);
      else setPasswordError(false);
      if (!firstName) setFirstNameError(true);
      else setFirstNameError(false);
      if (!lastName) setLastNameError(true);
      else setLastNameError(false);
      if (!passwordConfirm) setPasswordConfirmError(true);
      else setPasswordConfirmError(false);
      if (
        !(password && passwordConfirm && firstName && lastName && emailAddress)
      ) {
        setAuthError(true);
        setAuthErrorText("Please fill out all fields.");
        setPasswordError(true);
        setPasswordConfirmError(true);
      } else if (password && passwordConfirm && password != passwordConfirm) {
        setAuthError(true);
        setAuthErrorText("Passwords do not match.");
        setPasswordError(true);
        setPasswordConfirmError(true);
      } else if (
        password &&
        passwordConfirm &&
        password === passwordConfirm &&
        !passwordRegex.test(password)
      ) {
        setAuthError(true);
        setAuthErrorText(
          "Password does not meet all requirements or has invalid special characters. Make sure to follow the requirements below."
        );
        setPasswordError(true);
      }
    }
  };

  return (
    <DashboardContainer>
      <LoadingCircleComponent isOpen={isLoading} />
      <StyledH2 isMobile={isMobile}>Create an Account</StyledH2>
      <LoginForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {authError && (
          <ErrorBox>
            <img src="/assets/icons/error.svg" alt="error" />
            <ErrorText>{authErrorText}</ErrorText>
          </ErrorBox>
        )}
        <LoginInfo>
          <NameInput isMobile={isMobile}>
            <Input
              id="first-name"
              title="First Name"
              type="text"
              errorLabel="Please enter your first name."
              placeholder="John"
              value={firstName}
              isError={isFirstNameError}
              onChange={(e) => {
                setFirstName(e.target.value);
                setFirstNameError(false);
              }}
              required
            />
            <Input
              id="last-name"
              title="Last Name"
              type="text"
              errorLabel="Please enter your last name."
              placeholder="Doe"
              value={lastName}
              isError={isLastNameError}
              onChange={(e) => {
                setLastName(e.target.value);
                setLastNameError(false);
              }}
              required
            />
          </NameInput>
          <Input
            id="email"
            title="Email Address"
            type="email"
            errorLabel="Please enter in your email address."
            placeholder="xjohndoe@example.com"
            value={emailAddress}
            isError={isEmailAddressError}
            onChange={(e) => {
              setEmailAddress(e.target.value);
              setEmailAddressError(false);
            }}
            required
          />
          <PasswordInput
            id="password"
            title="Password"
            type="password"
            style={{ color: "#282828" }}
            placeholder="Enter your password"
            value={password}
            isError={isPasswordError}
            onChange={(e) => {
              setPassword(e.target.value.trim());
              setPasswordError(false);
            }}
            required
          />
          <Input
            id="password-confirm"
            title="Confirm Password"
            type="password"
            style={{ color: "#282828" }}
            errorLabel="Sorry, your password does not match."
            placeholder="Re-enter your password"
            value={passwordConfirm}
            isError={isPasswordConfirmError}
            onChange={(e) => {
              setPasswordConfirm(e.target.value);
              setPasswordConfirmError(false);
            }}
            required
          />
          {CLOSED_TESTING_REGISTER && <Input
              id="secret"
              title="Secret Testing Token"
              type="password"
              style={{ color: "#282828" }}
              errorLabel="Incorrect testing token, please contact a UofTHacks admin."
              value={secretCode}
              isError={isSecretCodeError}
              onChange={(e) => {
                setSecretCode(e.target.value);
                setSecretCodeError(false);
              }}
              required
          />}
          {CLOSED_TESTING_REGISTER && <div>
            The portal is currently in closed user testing and sign-ups are restricted to invited participants only.
          </div>}
        </LoginInfo>
        <div style={{ marginBottom: "20px" }}>
          <ReCAPTCHA
            sitekey="6Lcu7WYqAAAAAKcjNMaz3W2x8KN0hCpeZggIZXEY"
            onChange={(e) => {
              if (e) {
                setCaptchaToken(e);
              }
            }}
          />
        </div>
        <Button
          text="Create an account"
          colour="blue"
          filled={true}
          onClick={handleCreateAccount}
        />
        <p>
          Already have an account? <Link text="Log in here" href="/login" />
        </p>
      </LoginForm>
    </DashboardContainer>
  );
});

export default CreateAccountComponent;
