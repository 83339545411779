import React from "react";
import CreateAccountComponent from "../../components/create-account-form";
import { VerifyPageComponent } from "../../components/other";

const CreateAccount = () => {
  const [showVerify, setShowVerify] = React.useState(false);
  if (showVerify) {
    return (
      <VerifyPageComponent
        title="Verify Your Account"
        body={
          <span>
            Please check all folders including spam! <br />
            <br />
            The email will contain instructions on how to verify your account.
          </span>
        }
      />
    );
  }
  return <CreateAccountComponent onSuccess={() => {
    setShowVerify(true);
  }} />;  
};

export default CreateAccount;