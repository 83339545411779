import React, { useEffect, useState } from "react";
import {
  StyledSubjectivity,
  StyledInter,
  StyledImage,
  MainContainer,
  RSVPSuccessContainer,
} from "./index.styles";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  setContactName,
  setContactPhone,
  setContactRelation,
  setDemographic,
  setDidRSVP,
  setDietryRestrictions,
  setMerchSize,
} from "../../../redux/store";

import { API_BASE_URL, API_TYPE, API_VERSION } from "../../../api/apiConfig";
import InputOption from "../../input-option";
import Input from "../../input-box";
import SelectComponent from "../../input-select";
import ChipComponent from "../../chip";
import Button from "../../button";
import {
  demographicOptions,
  dietaryOptions,
  merchOptions,
  relationshipOptions,
} from "./dropdown-options";
import DialogBox from "../../dialog-box";
import LoadingCircleComponent from "../../loading-component";

type HomeProps = {
  isDrawerOpen?: boolean;
};

const RSVP: React.FC<HomeProps> = () => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [contactNameError, setContactNameError] = useState(false);
  const [contactPhoneError, setContactPhoneError] = useState(false);
  const [contactRelationError, setContactRelationError] = useState(false);
  const [dietaryRestrictionError, setDietaryRestrictionError] = useState(false);
  const [dietryOtherError, setDietryOtherError] = useState(false);
  const [merchSizeError, setMerchSizeError] = useState(false);
  const [demographicError, setDemographicError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const { didRSVP, authToken, userID } = useSelector(
    (state: RootState) => state.data
  );

  const {
    attending,
    contact_name,
    contact_phone,
    contact_relation,
    dietry_restrictions,
    merch_size,
    demographic,
  } = useSelector((state: RootState) => state.rsvp);

  const checkValid = (
    variable: string | boolean | number,
    nullValue: string | boolean | number,
    error: any
  ) => {
    if (
      variable === nullValue ||
      variable === undefined ||
      (typeof variable === "string" && variable.trim() === "")
    ) {
      error(true);
      return false;
    }
    error(false);
    return true;
  };

  const checkInputs = () => {
    let valid = true;
    valid = checkValid(contact_name, "", setContactNameError) && valid;
    valid = checkValid(contact_phone, "", setContactPhoneError) && valid;
    valid = checkValid(contact_relation, "", setContactRelationError) && valid;
    if (contact_relation.includes("Other - ")) {
      valid =
        checkValid(contact_relation.slice(8), "", setContactRelationError) &&
        valid;
    }
    valid = checkValid(merch_size, "", setMerchSizeError) && valid;
    if (merch_size.includes("Other - ")) {
      valid = checkValid(merch_size.slice(8), "", setMerchSizeError) && valid;
    }

    const isEmptyDietryRestrictions = dietry_restrictions.length === 0;

    const invalidOtherDietryRestriction =
      dietry_restrictions.includes("Other") ||
      dietry_restrictions.includes("Other - ") ||
      dietry_restrictions.includes("Other -");

    if (isEmptyDietryRestrictions) {
      setDietaryRestrictionError(true);
      setDietryOtherError(false);
    } else if (invalidOtherDietryRestriction) {
      setDietaryRestrictionError(true);
      setDietryOtherError(true);
    } else {
      setDietaryRestrictionError(false);
      setDietryOtherError(false);
    }

    valid =
      !isEmptyDietryRestrictions && !invalidOtherDietryRestriction && valid;

    const invalidOtherDemographic =
      demographic.includes("Other") ||
      demographic.includes("Other - ") ||
      demographic.includes("Other -");
    if (invalidOtherDemographic) {
      setDemographicError(true);
    } else {
      setDemographicError(false);
    }

    valid = !invalidOtherDemographic && valid;

    return valid;
  };

  const handleRSVPSubmit = () => {
    if (!checkInputs()) {
      return;
    }

    setShowLoading(true);

    fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/rsvp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        user_id: userID,
        emg_name: contact_name,
        emg_relation: contact_relation,
        emg_phone_num: contact_phone,
        user_size: merch_size,
        diet_condition: dietry_restrictions,
        ethnicity: demographic,
        in_person: attending,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setShowError(false);
          dispatch(setDidRSVP(true));
          setErrorMessage("");
          setShowLoading(false);
        } else {
          res.json().then((data) => {
            setShowError(true);
            setErrorMessage(data.error ?? data.message);
          });
          setShowLoading(false);
        }
      })
      .catch((err) => {
        setShowError(true);
        setErrorMessage(err);
        setShowLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (didRSVP) {
    return (
      <RSVPSuccessContainer>
        <StyledSubjectivity
          mobile={isMobile}
          style={{
            alignContent: "center",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          One More Step!
        </StyledSubjectivity>
        <img src="/assets/rsvp/celebrate.svg" onClick={() => navigate("/")} />
        <StyledInter style={{ marginTop: "2rem" }}>
          Your RSVP has been submitted! <br /> Join our UofTHacks 11 Discord to
          confirm your spot! An invite link is on the homepage. <br /> <br />{" "}
          Please refer to the FAQ for more information about our Discord Server!
        </StyledInter>
        <Button text="Continue" colour="blue" onClick={() => navigate("/")} />
      </RSVPSuccessContainer>
    );
  }
  return (
    <>
      <StyledSubjectivity mobile={isMobile}>
        <DialogBox
          isOpen={showError}
          title="Something went wrong"
          body={"Something doesn't look right here. Please try again later."}
          extraBodyContent={
            <>
              <br />
              Error Message: {errorMessage}
            </>
          }
          button1Text="Okay"
          button1Click={() => setShowError(false)}
          onClose={() => setShowError(false)}
        />
        <LoadingCircleComponent isOpen={showLoading} />
        <StyledImage
          src="/assets/home-page/back-button.svg"
          onClick={() => navigate("/")}
        />
        UofTHacks 12 RSVP Form
      </StyledSubjectivity>
      <MainContainer isMobile={isMobile}>
        <InputOption
          id="atd-uofthacks"
          title="Will you be attending UofTHacks 12?"
          type="radio"
          errorLabel="Please select an option."
          options={[
            new Map<string, any>([
              ["value", "yesRSVP"],
              ["text", "Yes"],
              ["checked", attending],
            ]),
            new Map<string, any>([
              ["value", "noRSVP"],
              ["text", "No"],
              ["checked", !attending],
            ]),
          ]}
          requiredLabelStar
        />
        {attending && (
          <>
            <Input
              id="contact-name"
              title="Emergency Contact Name"
              type="text"
              errorLabel="Please enter contact name."
              placeholder="e.g. Name"
              value={contact_name}
              isError={contactNameError}
              onChange={(e) => {
                dispatch(setContactName(e.target.value));
              }}
              requiredStar
            />
            <Input
              id="contact-number"
              title="Emergency Contact Phone Number"
              type="tel"
              errorLabel="Please enter contact number."
              placeholder="e.g. 1234567890"
              value={contact_phone}
              isError={contactPhoneError}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  (e as any).nativeEvent.inputType ===
                    "deleteContentBackward" ||
                  (e as any).nativeEvent.inputType === "deleteContentForward" ||
                  (e as any).nativeEvent.inputType === "deleteByCut"
                ) {
                  dispatch(setContactPhone(value));
                  return;
                }
                if (value.length > 15) {
                  return;
                }
                const lastChar = value.charAt(value.length - 1);
                if (lastChar && !lastChar.match(/[0-9+]/)) {
                  return;
                }
                if (lastChar === "+" && contact_phone.includes("+")) {
                  return;
                }
                if (lastChar === "+" && value.charAt(0) !== "+") {
                  dispatch(setContactPhone("+" + value.slice(0, -1)));
                  return;
                }
                dispatch(setContactPhone(value));
              }}
              requiredStar
            />
            <SelectComponent
              id="relation"
              title="Emergency Contact Relationship"
              options={relationshipOptions}
              errorLabel="Please select relationship."
              isError={contactRelationError}
              value={contact_relation}
              onChange={(val: string) => {
                dispatch(setContactRelation(val));
              }}
              requiredStar
            />
            <ChipComponent
              options={dietaryOptions}
              selectedOptions={dietry_restrictions}
              onChange={(selected) =>
                dispatch(setDietryRestrictions(selected as string[]))
              }
              id="dietry-restrictions"
              title="What are your dietary restrictions?"
              errorLabel={
                dietryOtherError
                  ? "Please fill in Other dietary restriction."
                  : "Please select dietary restrictions."
              }
              isError={dietaryRestrictionError}
              isTextError={dietryOtherError}
              requiredStar
            />
            <SelectComponent
              id="t-shirt"
              title="For hacker merchandise, what size T-shirt (Unisex) would you prefer?"
              options={merchOptions}
              errorLabel="Please select a tshirt size."
              isError={merchSizeError}
              value={merch_size}
              onChange={(val: string) => {
                dispatch(setMerchSize(val));
              }}
              requiredStar
            />
            <ChipComponent
              options={demographicOptions}
              selectedOptions={demographic}
              onChange={(selected) =>
                dispatch(setDemographic(selected as string[]))
              }
              id="demographic"
              title="[Optional Demographics Survey]: 
        Which of the following ethnicities do you identify with?"
              errorLabel="Please enter Other Demographic."
              isError={demographicError}
              isTextError={demographicError}
              dontAbsolute
            />
            <div style={{ marginBottom: "2rem" }} />
          </>
        )}
        <Button
          text="RSVP"
          colour="#a050a0"
          disabled={!attending}
          onClick={handleRSVPSubmit}
        />

        {!attending && (
          <StyledInter style={{ marginTop: "2rem" }}>
            You don't need to RSVP if you're not attending the event. However,
            if you change your mind, you can still RSVP by January 18, 2024.
          </StyledInter>
        )}
      </MainContainer>
    </>
  );
};

export { RSVP };
