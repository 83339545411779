import React from "react";
import { ApplicationPage } from "./index.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import DashboardContainer from "../dashboard-container";
import {StyledH2} from "../login-form/index.styles";
import Button from "../button";
import styled from "styled-components";
import {useMobileDetect} from "../../hooks/useMobileDetect";
import {useNavigate} from "react-router-dom";
import Manrope from "../manrope";

const SuccessComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const StyledImage = styled.img`
  margin: 2rem 0;
`;

const StyledManrope = styled(Manrope)`
  color: #282828;

  text-align: center;
  leading-trim: both;

  text-edge: cap;
  /* Body 1 (16px) */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2rem;
`;



const AppPage5 = React.memo(() => {
  const { app_page } = useSelector((state: RootState) => state.application);
  const isMobile = useMobileDetect()
    const navigate = useNavigate()
  return (
    <ApplicationPage id="page-5" show={app_page==5}>
            <SuccessComponent>
                <StyledImage src="/assets/icons/checked-circle.svg" alt="success" />
                <StyledManrope>
                    Your application has been submitted.
                </StyledManrope>
            </SuccessComponent>
    </ApplicationPage>
  );
});

export default AppPage5;
