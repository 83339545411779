export const ethnicityOptions = [
    "Indigenous/Aboriginal/Native American",
    "African American/Black",
    "East Asian/Pacific Islander",
    "South Asian",
    "White/Caucasian",
    "Hispanic/Latino",
    "Mixed race",
    "Prefer not to say",
  ];
  