import Inter from "../inter";
import styled from "styled-components";

interface Props {
  text: string;
  href?: string;
}



const StyledInter = styled(Inter)`
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: inline;
  color: var(--Default-Stroke, #0DAEF1);
`;

const StyledLink = styled.a`
  color: #0DAEF1;
  text-decoration: none;

  text-align: right;
  leading-trim: both;

  text-edge: cap;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Link = (props: Props) => {
  return (
      <LinkContainer>
        <StyledInter>
          <StyledLink href={props.href}>{props.text}</StyledLink>
        </StyledInter>
      </LinkContainer>
  );
};

export default Link;
