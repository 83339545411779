import React from "react";
import { binTexts } from "../characters";
import { CenterContent, PageContainer } from "./results.styles";
import {
  QuizContainer,
  HeaderContainer,
  HeaderTitle,
  Description,
  NavigationContainer,
} from "../index.styles";
import { useNavigate } from "react-router-dom";
import { useMobileDetect } from "../../../hooks/useMobileDetect";
import { useDispatch } from "react-redux";
import { setCharacterName } from "../../../redux/store";
import Button from "../../button";

type ResultsProps = {
  onRestart: () => void;
  header: string;
  paragraph: string;
  imageSrc: string;
  imgBackground?: string;
};

const Results = ({
  onRestart,
  header,
  paragraph,
  imageSrc,
  imgBackground,
}: ResultsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMobileDetect();

  const handleRetry = () => {
    dispatch(setCharacterName(undefined)); // Reset character name to allow retry
    navigate("/quiz"); // Navigate to quiz page
  };

  return (
    <PageContainer isQuiz goHome>
      <QuizContainer>
        <HeaderContainer>
          <HeaderTitle>{header}</HeaderTitle>
        </HeaderContainer>

        <CenterContent imgBackground={imgBackground}>
          <img src={imageSrc} alt={header} width='100%' />
        </CenterContent>

        <Description>
          <p>{paragraph}</p>
        </Description>
      </QuizContainer>
      <NavigationContainer
        isMobile={isMobile}
        style={{ justifyContent: "space-between" }}
      >
        <Button
          text="Retry Quiz"
          colour="#225C90"
          onClick={handleRetry}
          style={{
            minWidth: isMobile ? "7rem" : "10rem",
          }}
        />
        <Button
          text="Finish Quiz"
          colour="#225C90"
          onClick={() => {
            navigate("/");
          }}
          style={{
            minWidth: isMobile ? "7rem" : "10rem",
          }}
        />
      </NavigationContainer>
    </PageContainer>
  );
};

export default Results;
