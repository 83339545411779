import React from "react";
import DashboardComponent from "../../components/dashboard";

type DashboardProps = {
    showFAQ?: boolean;
    showProfile?: boolean;
    showRSVP?: boolean;
    showSchedule?: boolean;
    showApplication?: boolean;
    showQuiz?: boolean;
};

const Dashboard = ({
                       showFAQ,
                       showProfile,
                       showRSVP,
                       showSchedule,
                       showApplication,
                       showQuiz,
                   }: DashboardProps) => {
    return (
        <DashboardComponent
            showFAQ={showFAQ}
            showProfile={showProfile}
            showRSVP={showRSVP}
            showSchedule={showSchedule}
            showApplication={showApplication}
            showQuiz={showQuiz}
        />
    );
};

export default Dashboard;
