import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Modal from "../modal";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

const wobble = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  15%, 85% {
    transform: translateX(-10px);
  }
  30%, 70% {
    transform: translateX(10px);
  }
  45%, 55% {
    transform: translateX(-5px);
  }
  50%, 60% {
    transform: translateX(5px);
  }
`;

const rubberBand = keyframes`
  0% {
    transform: scale(1);
  }
  30% {
    transform: scaleX(1.25) scaleY(0.75);
  }
  40% {
    transform: scaleX(0.75) scaleY(1.25);
  }
  60% {
    transform: scaleX(1.15) scaleY(0.85);
  }
  100% {
    transform: scale(1);
  }
`;

const bounceCircle = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) scale(1);
  }
  40% {
    transform: translateY(-20px) scale(1.2);
  }
  60% {
    transform: translateY(-10px) scale(1.1);
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const BounceAnimalSVG = styled.img`
  margin: 0.5rem;
  width: 200px;
  height: 200px;
  animation: ${spin} 1s linear infinite;

  &.wobble {
    animation: ${wobble} 1s ease-in-out infinite;
  }

  &.rubberBand {
    animation: ${rubberBand} 1s ease-in-out infinite;
  }

  &.rotate {
    animation: ${rotate} 1s linear infinite;
  }

  &.pulse {
    animation: ${pulse} 1s ease-in-out infinite;
  }

  &.bounce {
    animation: ${bounce} 1s ease-in-out infinite;
  }

  &.bounceCircle {
    animation: ${bounceCircle} 1s ease-in-out infinite;
  }
`;

const animalAnimations: Record<string, string> = {
  cat: "rubberBand",
  hamster: "wobble",
  axolotl: "spin",
  goat: "rubberBand",
  hedgehog: "spin",
  moose: "pulse",
  owl: "bounceCircle",
  eagle: "bounce",
};
const orderedAnimals = [
  "axolotl",
  "goat",
  "owl",
  "hamster",
  "hedgehog",
  "cat",
  "goat",
  "moose",
  "eagle",
];

type LoadingAnimalProps = {
  isOpen: boolean;
};

const QuizLoadingComponent: React.FC<LoadingAnimalProps> = ({ isOpen }) => {
  const [animalIndex, setAnimalIndex] = useState<number>(0);
  const [randomAnimal, setRandomAnimal] = useState<string>("");
  const [animationClass, setAnimationClass] = useState<string>("");

  const preloadImages = () => {
    orderedAnimals.forEach((animal) => {
      const img = new Image();
      img.src = `/assets/characters/${animal}.svg`;
      img.onload = () => {};
    });
  };

  useEffect(() => {
    preloadImages();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const selectedAnimal = `/assets/characters/${orderedAnimals[animalIndex]}.svg`;
    const selectedAnimationClass =
      animalAnimations[orderedAnimals[animalIndex]];

    setRandomAnimal(selectedAnimal);
    setAnimationClass(selectedAnimationClass);
    setAnimalIndex((animalIndex + 1) % orderedAnimals.length);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen}>
      <LoadingContainer>
        {randomAnimal && (
          <BounceAnimalSVG className={animationClass} src={randomAnimal} />
        )}
      </LoadingContainer>
    </Modal>
  );
};

export default QuizLoadingComponent;
