import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  ApplicationPage,
  StyledInter,
  StyledLink,
  RequiredStarStyle,
} from "./index.styles";
import InputOption from "../input-option";

const AppPage4 = React.memo(({ isTCError }: { isTCError: boolean }) => {
  const { app_page, app_tc_inPerson, app_tc_mlh, app_tc_subscribe, app_completed } =
    useSelector((state: RootState) => state.application);

  return (
    <ApplicationPage id="page-4" show={app_page==4}>
      <InputOption
        id="conditions"
        title="Terms & Conditions"
        type="checkbox"
        errorLabel="Sorry, to proceed you must agree to the required terms and conditions. If you have any questions, please contact us at contact@uofthacks.com"
        isError={isTCError}
        options={[
          new Map<string, any>([
            ["value", "in-person"],
            ["checked", app_tc_inPerson],
            [
              "text",
              <span>
                I can attend UofTHacks 12 in-person.
                <RequiredStarStyle>*</RequiredStarStyle>
              </span>,
            ],
          ]),
          new Map<string, any>([
            ["value", "mlh-coc"],
            ["text", "I have read and agree to the MLH Code of Conduct ()"],
            ["checked", app_tc_mlh],
            [
              "text",
              <span>
                I have read and agree to the{" "}
                <StyledInter>
                  <StyledLink
                    href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf"
                    target="_blank"
                  >
                    MLH Code of Conduct
                  </StyledLink>
                </StyledInter>
                <RequiredStarStyle>*</RequiredStarStyle>
              </span>,
            ],
          ]),
          new Map<string, any>([
            ["value", "subscribe"],
            [
              "text",
              "I authorize MLH to send me an email where I can further opt into the MLH Hacker, Events, or Organizer Newsletters and other communications from MLH.",
            ],
            ["checked", app_tc_subscribe],
          ]),
        ]}
        //required
        disabled={app_completed}
      />
    </ApplicationPage>
  );
});

export default AppPage4;
