import React from "react";
import { useNavigate } from "react-router-dom";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import Button from "../../components/button";
import DashboardContainer from "../../components/dashboard-container";
import {
  DashboardContents,
  ResetForm,
  FlexButtonContainer,
  InputContainer,
  StyledH2,
  StyledInter,
  ErrorBox,
  ErrorText,
} from "./index.styles";
import Input from "../input-box";
import LoadingCircleComponent from "../loading-component";
import { API_BASE_URL, API_TYPE, API_VERSION } from "../../api/apiConfig";
import SpaceGrotesk from "../space-grotesk";
import {useSelector} from "react-redux";
import {RootState} from "@/redux/store";

const ResetPassComponent = ({ onClick }: any) => {
  const navigate = useNavigate();
  const isMobile = useMobileDetect();

  const [email, setEmail] = React.useState("");
  const [isEmailError, setEmailError] = React.useState(false);

  const [authError, setAuthError] = React.useState(false);
  const [authErrorText, setAuthErrorText] = React.useState("Error");
  const [isLoading, setIsLoading] = React.useState(false);

  const emailCheck =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const handleOnClick = () => {
    if (!email || !emailCheck.test(email)) {
      setEmailError(true);
      return;
    }
    setIsLoading(true);
    fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/reset_password/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ hacker_email: email }),
    })
      .then((response) => {
        setIsLoading(false);
        onClick();
      })
      .catch((error) => {
        // something went wrong on our end
        setAuthError(true);
        setAuthErrorText(
          "Something doesn't look right on our end. Please try again later."
        );
        console.error(error);
        setIsLoading(false);
      });
  };
  return (
    <DashboardContainer>
      <LoadingCircleComponent isOpen={isLoading} />
      <DashboardContents>
        <StyledH2 isMobile={isMobile}>Reset Your Password</StyledH2>
        <ResetForm
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {authError && (
            <ErrorBox>
              <img src="/assets/icons/error.svg" alt="error" />
              <ErrorText>{authErrorText}</ErrorText>
            </ErrorBox>
          )}

          <StyledInter>
            Please enter the email account you registered with below to reset
            your password.
          </StyledInter>
          <InputContainer>
            <Input
              id="email"
              title="Email Address"
              type="email"
              placeholder="Enter your email"
              errorLabel="Please enter a valid email."
              required
              isError={isEmailError}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(false);
              }}
              value={email}
            />
          </InputContainer>
          <div />
        </ResetForm>
        <FlexButtonContainer isMobile={isMobile}>
          <Button
            text="Back"
            colour="blue"
            onClick={() => {
              navigate(-1);
            }}
            style={isMobile ? { minWidth: "150px" } : {}}
          />
          <Button
            text="Send Email"
            colour="blue"
            onClick={handleOnClick}
            style={isMobile ? { minWidth: "150px" } : {}}
          />
        </FlexButtonContainer>
      </DashboardContents>
    </DashboardContainer>
  );
};

export default ResetPassComponent;
