import styled from "styled-components";
import Subjectivity from "../subjectivity";
import Inter from "../inter";
import SpaceGrotesk from "../space-grotesk";

type LoginProps = {
  isMobile?: boolean | null;
};

const DashboardContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1.25rem;
`;

const StyledH2 = styled(SpaceGrotesk)<LoginProps>`
  text-shadow: none;
  font-size: ${(props) => (props.isMobile ? "24px" : "48px")};
  letter-spacing: ${(props) => (props.isMobile ? "-1px" : "-2.4px")};
  margin-top: ${(props) => (props.isMobile ? "0" : "1.9rem")};
  margin-bottom: 20px;
  color: var(--Default-Stroke, #225C90);
`;

const StyledInter = styled(Inter)<LoginProps>`
  margin: 3rem 0;
  font-size: ${(props) => (props.isMobile ? "10px" : "16px")};
`;

const StyledSVG = styled.svg`
  border: 2px solid red;
`;

const ImageWrapper = styled.div`
  padding: 30px 0px 10px 0px;
`;

const TextWrapper = styled.div<LoginProps>`
  width: ${(props) => (props.isMobile ? "70vw" : "35vw")};
`;

export { DashboardContents, StyledH2, StyledInter, StyledSVG, ImageWrapper, TextWrapper };
