import Inter from "../inter";
import styled from "styled-components";
// import { useMobileDetect } from "../../hooks/useMobileDetect";

const StyledInterButton = styled(Inter)`
  text-align: center;
  leading-trim: both;
  font-size: 14px;
  text-edge: cap;
  /* Button */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
  margin: 10px;
`;

const StyledInterRSVPButtonText = styled(Inter)`
  color: var(--Black, var(--Default-Stroke, #282828));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.025rem;
`;

type ButtonProps = {
  colour?: string;
  textColour?: string;
  notTransparent?: boolean;
  isMobile?: boolean | null;
  filled?: boolean;
  disabled?: boolean;
};
const StyledButton = styled.button<ButtonProps>`
  background-color: ${(props: ButtonProps) =>
    props.filled
      ? props.colour === "blue"
        ? "#225C90"
        : props.colour
      : "transparent"};
  color: ${(props: ButtonProps) =>
    props.filled ? "#f9f9f9" : props.textColour === "blue"
        ? "#225C90"
        : props.textColour};
  border: 1px solid ${(props: ButtonProps) => props.colour === "blue"
      ? "#225C90"
      : props.colour};
  padding: 0 20px;
  height: 50px;
  min-width: ${(props) => (props.isMobile ? "90px" : "15rem")};
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  &:hover {
    background-color: ${(props: ButtonProps) =>
      props.filled ? "transparent" : props.colour === "blue" ? "#225C90" : props.colour};
    color: ${(props: ButtonProps) => (props.filled ? "#225C90" : "#f9f9f9")};
    border-color: ${(props: ButtonProps) =>
      props.filled ? (props.colour === "blue"
          ? "#225C90" : props.colour) : "#f9f9f9"};
    text-decoration: ${(props) => (props.filled ? "underline" : "none")};
    cursor: pointer;
  }

  ${(props: ButtonProps) =>
    props.disabled &&
    `
    background-color: grey;
    &:hover {
      border: 1px solid #282828;
      text-decoration: none;
      background-color: grey;
      color: #F9F9F9;
      cursor: not-allowed;
    }
  `}
`;

const RSVPButtonContainer = styled(StyledButton)`
  &:hover {
    background-color: #f9f9f9;
    color: #f9f9f9;
    text-decoration: none;
    min-width: ${(props) => (props.isMobile ? "90px" : "11rem")};
    padding: 0 20px;
    height: 50px;
    border: 1px solid #282828;
    border-radius: 10px;
    transition: ease-in 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export {
  StyledInterButton,
  StyledButton,
  StyledInterRSVPButtonText,
  RSVPButtonContainer,
};
