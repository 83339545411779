import React from "react";

import {
  Label,
  StyledInterLabel,
  StyledInterTextArea,
  StyledTextArea,
  ErrorLabel,
  ErrorText,
  RequiredStarStyle,
} from "./index.styles";

interface Props {
  type?: string;
  id: string;
  title?: string;
  style?: any;
  isError?: boolean;
  errorLabel?: string;
  placeholder?: string;
  required?: boolean;
  value?: string;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  requiredStar?: boolean;
  maxWords?: number;
  makeBig?: boolean;
  disabled?: boolean;
}

const TextArea = ({
  id,
  title,
  style,
  isError,
  errorLabel,
  placeholder,
  required,
  value,
  maxLength,
  maxWords,
  onChange,
  requiredStar,
  makeBig,
  disabled = false,
}: Props) => {
  // const [isOpen, setIsOpen] = React.useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (maxWords) {
      const words = e.target.value.split(" ");
      if (words.length <= maxWords) {
        onChange && onChange(e);
      } else {
        // setIsOpen(true);
        return;
      }
    } else {
      onChange && onChange(e);
    }
  };

  return (
    <div style={{ marginTop: "30px" }}>
      {/* <DialogBox
        isOpen={isOpen}
        title="You've reached the maximum word limit."
        body="You've reached the max word limit"
        onClose={() => {
          setIsOpen(false);
        }}
      /> */}
      {requiredStar ? (
        <Label htmlFor={id}>
          <StyledInterLabel>
            {title}
            <RequiredStarStyle>*</RequiredStarStyle>
          </StyledInterLabel>
        </Label>
      ) : (
        <Label htmlFor={id}>
          <StyledInterLabel>{title}</StyledInterLabel>
        </Label>
      )}
      <StyledInterTextArea isError={isError} disabled={disabled}>
        <StyledTextArea
          id={id}
          style={style}
          placeholder={placeholder}
          required={required}
          value={value}
          rows={4}
          maxLength={maxLength}
          onChange={handleChange}
          makeBig={makeBig}
          disabled={disabled}
        />
      </StyledInterTextArea>
      <ErrorLabel isError={isError}>
        <img src="/assets/icons/error.svg" alt="error" />
        <ErrorText>{errorLabel}</ErrorText>
      </ErrorLabel>
    </div>
  );
};

export default TextArea;
