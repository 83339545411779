import React, { useEffect, useState } from "react";
import { OptionButton, OptionText, OptionsContainer } from "./index.styles";
import {
  setQuizQ1,
  setQuizQ2,
  setQuizQ3,
  setQuizQ4,
  setQuizQ5,
  setQuizQ6,
} from "../../redux/store";
import { useDispatch } from "react-redux";
import {useMobileDetect} from "../../hooks/useMobileDetect";

type QuestionTypes = {
  handleOptionClick: (index: any) => void;
  options: string[];
  background: string;
  selectedBackground: string;
  border: string;
  currentQuestion: number;
  selectedIndex: number;
  showOptions?: boolean;
};

const OptionsComponent = ({
  handleOptionClick,
  options,
  background,
  selectedBackground,
  border,
  currentQuestion,
  selectedIndex,
  showOptions = true,
}: QuestionTypes) => {
  const isMobile = useMobileDetect()
  const [selectedOption, setSelectedOption] = useState(3);

  useEffect(() => {
    setTimeout(() => {
      setSelectedOption(selectedIndex);
    }, 1);
  }, []);

  const dispatch = useDispatch();

  const storeSelectedOption = (index: number) => {
    if (currentQuestion === 0) {
      dispatch(setQuizQ1(index));
    } else if (currentQuestion === 1) {
      dispatch(setQuizQ2(index));
    } else if (currentQuestion === 2) {
      dispatch(setQuizQ3(index));
    } else if (currentQuestion === 3) {
      dispatch(setQuizQ4(index));
    } else if (currentQuestion === 4) {
      dispatch(setQuizQ5(index));
    } else if (currentQuestion === 5) {
      dispatch(setQuizQ6(index));
    }
  };

  return (
    <OptionsContainer showOptions={showOptions}>
      {options.map((option, index) => (
        <OptionButton
          key={index}
          isSelected={selectedOption === index}
          onClick={() => {
            setSelectedOption(index);
            storeSelectedOption(index);
            handleOptionClick(index);
          }}
          optionColor={background}
          border={border}
          optionSelectedColor={selectedBackground}
        >
          <OptionText isMobile={isMobile}>{option}</OptionText>
        </OptionButton>
      ))}
    </OptionsContainer>
  );
};

export default OptionsComponent;
