import React, { useEffect, useState } from "react";
import Drawer from "./drawer";
import Content from "./content";
import { useMobileDetect } from "../../hooks/useMobileDetect";

type DashboardProps = {
  showFAQ?: boolean;
  showSchedule?: boolean;
  showProfile?: boolean;
  showRSVP?: boolean;
  showApplication?: boolean;
  showQuiz?: boolean;
};

export type ContentType = 'faq' | 'profile' | 'schedule' | 'rsvp' | 'application' | 'quiz' | null;

const DashboardComponent: React.FC<DashboardProps> = ({
  showFAQ,
  showProfile,
  showRSVP,
  showSchedule,
    showApplication,
    showQuiz,
}) => {
  const isMobile = useMobileDetect();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  let contentType: ContentType = null

  if (showFAQ) {
    contentType = 'faq';
  } else if (showProfile) {
    contentType = 'profile';
  } else if (showSchedule) {
    contentType = 'schedule';
  } else if (showRSVP) {
    contentType = 'rsvp';
  } else if (showApplication) {
    contentType = 'application'
  } else if (showQuiz) {
    contentType  = 'quiz'
  }

  return (
    <>
      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} contentType={contentType} />
      <Content
        isDrawerOpen={isDrawerOpen}
        onToggleDrawer={toggleDrawer}
        showFAQ={showFAQ}
        showProfile={showProfile}
        showRSVP={showRSVP}
        showSchedule={showSchedule}
        showApplication={showApplication}
        showQuiz={showQuiz}
      />
    </>
  );
};

export default DashboardComponent;
