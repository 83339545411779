import React from "react";
import DashboardContainer from "../dashboard-container";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  DashboardContents,
  StyledH2,
  StyledInter,
  StyledSVG,
  ImageWrapper,
  TextWrapper
} from "./index.styles";

type VerifyProps = {
  title?: string;
  body?: any;
};

const VerifyPageComponent = ({
  title = "Account Verified",
  body = "Verify your account",
}: VerifyProps) => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  // const { isAuthenticated } = useSelector((state: RootState) => state.data);
  return (
    <DashboardContainer>
      <DashboardContents>
        <StyledH2 isMobile={isMobile}>{title}</StyledH2>
        <ImageWrapper>
          <img
            src="/assets/register-flow/pc-firewall.svg"
            alt="Verification SVG"
            width="60"
            height="60"
          />
        </ImageWrapper>
        <TextWrapper>
          <StyledInter>{body}</StyledInter>
        </TextWrapper>
        <Button
          text="Back to login page"
          colour="blue"
          onClick={() => {
            navigate("/login");
          }}
        />
      </DashboardContents>
    </DashboardContainer>
  );
};

export default VerifyPageComponent;
