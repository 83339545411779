import styled from "styled-components";
import DashboardContainer from "../../dashboard-container";

const PageContainer = styled(DashboardContainer)`
  max-width: 55rem;
  color: #ecd8ec;
`;

const CenterContent = styled.div<{ imgBackground?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0; // Remove any extra margin
  padding: 0; // Remove any extra padding

  /* Remove border radius and background */
  border: none;
  background: transparent;
  box-shadow: none; // Remove shadow if not needed
`;

export { CenterContent, PageContainer };
