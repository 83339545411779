import Inter from "../inter";
import styled from "styled-components";
import InterSemiBold from "../inter-semi-bold";

type ErrorProps = {
  isError?: boolean | null;
  disabled?: boolean;
};

type PasswordProps = {
  type?: string;
  id: string;
  onClick: () => void;
};

const Label = styled.label`
  display: block;
`;

const StyledInterLabel = styled(InterSemiBold)`
  font-size: 16px;
  text-align: left;

  color: var(--Default-Stroke, #282828);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledInterInput = styled(Inter)<ErrorProps>`
  font-size: 14px;
  text-align: left;
  color: #555555;
  background-color: white;
  border: ${(props) =>
    props.isError ? "1px solid #CB3101" : "1px solid #282828"};
  height: 40px;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  flex: 1;
  &:focus-within {
    outline: ${(props) =>
      props.isError ? "1px solid #CB3101" : "1px solid #282828"};
  }
  ${(props) =>
    props.disabled &&
    `
    border: 1px solid rgba(40, 40, 40, 0.5);
    `}
`;

const InputBox = styled.input<ErrorProps>`
  flex: 3;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #FFFFFF;
  padding: 0 20px;
  margin
  color: #555;
  leading-trim: both;
  text-edge: cap;
  &:focus {
    outline: none;
  }
  /* Body 2 (14px) */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    background-color: lightgray;
    cursor: not-allowed;
    color: black;
  `}
`;

const ErrorLabel = styled(Inter)<ErrorProps>`
  display: ${(props) => (props.isError ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #cb3101;
`;

const ErrorText = styled.div`
  height: 100%;
  display: inline;
  margin: auto 10px;
`;

const EyeImage = styled.img<PasswordProps>`
  display: ${(props) => (props.type === "password" ? "inline" : "none")};
  flex: 0.1;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  align-self: center;
  &:hover {
    cursor: pointer;
  }
`;

const RequiredStarStyle = styled.span`
  color: #cb3101;
  margin-left: 0.25rem;
`;

export {
  InputBox,
  ErrorLabel,
  ErrorText,
  EyeImage,
  Label,
  StyledInterLabel,
  StyledInterInput,
  RequiredStarStyle,
};
