import Inter from "../inter";
import styled, { keyframes } from "styled-components";
import InterSemiBold from "../inter-semi-bold";

type ErrorProps = {
  isError?: boolean | null;
};

type ShowProps = {
  show?: boolean | undefined;
};

type PasswordProps = {
  type?: string;
  id: string;
  onClick: () => void;
};

const Label = styled.label`
  display: block;
`;

const StyledInterLabel = styled(InterSemiBold)`
  font-size: 16px;
  text-align: left;

  color: var(--Default-Stroke, #282828);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledInterInput = styled(Inter)<ErrorProps>`
  font-size: 14px;
  background-color: #ffff;
  text-align: left;
  color: #555555;
  border: ${(props) =>
    props.isError ? "1px solid #CB3101" : "1px solid #282828"};
  height: 40px;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  flex: 1;
  &:focus-within {
    outline: ${(props) =>
      props.isError ? "1px solid #CB3101" : "1px solid #282828"};
  }
`;

const InputBox = styled.input<ErrorProps>`
  flex: 3;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #ffff;
  padding: 0 20px;
  color: #555;
  leading-trim: both;
  text-edge: cap;
  &:focus {
    outline: none;
  }
  /* Body 2 (14px) */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
`;

const ErrorLabel = styled(Inter)<ErrorProps>`
  display: ${(props) => (props.isError ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #cb3101;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
    transform: translateY(-10%);
  }
  
  to {
    opacity: 1;
    height: 100%;
    transform: translateY(0);
  }
`;

const NonErrorLabel = styled(Inter)<ShowProps>`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #000;
  animation: ${fadeIn} 0.7s ease-in-out;
`;

const ErrorText = styled.div`
  height: 100%;
  display: inline;
  margin: auto 10px;
`;

const EyeImage = styled.img<PasswordProps>`
  display: ${(props) => (props.type === "password" ? "inline" : "none")};
  flex: 0.1;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  align-self: center;
  &:hover {
    cursor: pointer;
  }
`;

export {
  InputBox,
  ErrorLabel,
  NonErrorLabel,
  ErrorText,
  EyeImage,
  Label,
  StyledInterLabel,
  StyledInterInput,
};
