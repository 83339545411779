import React, { useEffect, useRef, useState } from "react";
import Input from "../input-box";
import {
    Label,
    StyledInterLabel,
    DropdownContainer,
    DropdownHeader,
    DisplayText,
    DropdownList,
    ErrorLabel,
    ErrorText,
    ArrowImage,
    DropdownItem,
    RequiredStarStyle,
} from "./index.styles";

type SelectSearchProps = {
    options: string[];
    id: string;
    title: string;
    placeholder?: string;
    value: string;
    isError?: boolean;
    isTextError?: boolean;
    errorLabel?: string;
    onChange: (val: string) => void;
    requiredStar?: boolean;
    disabled?: boolean;
};

const SelectSearchComponent: React.FC<SelectSearchProps> = ({
                                                                options,
                                                                id,
                                                                title,
                                                                placeholder = "-Select-",
                                                                value,
                                                                isError,
                                                                isTextError,
                                                                errorLabel,
                                                                onChange,
                                                                requiredStar,
                                                                disabled = false,
                                                            }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isOtherSelected, setIsOtherSelected] = useState(value === "Other");

    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
        setIsOpen(false);
        setIsOtherSelected(option === "Other");
        onChange(option);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchTerm(searchValue);
        const filtered = options.filter((option) =>
            option.toLowerCase().includes(searchValue)
        );
        setFilteredOptions(filtered);
    };

    const handleOtherInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const customValue = "Other - " + event.target.value;
        setSelectedOption(customValue);
        onChange(customValue);
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (selectedOption === "" && value) {
            setSelectedOption(value);
        }
    }, [value]);

    return (
        <>
            <DropdownContainer ref={dropdownRef}>
                {requiredStar ? (
                    <Label htmlFor={id}>
                        <StyledInterLabel>
                            {title}
                            <RequiredStarStyle>*</RequiredStarStyle>
                        </StyledInterLabel>
                    </Label>
                ) : (
                    <Label htmlFor={id}>
                        <StyledInterLabel>{title}</StyledInterLabel>
                    </Label>
                )}
                <DropdownHeader
                    onClick={disabled ? () => {} : toggleDropdown}
                    isOpen={isOpen}
                    isError={isError}
                    disabled={disabled}
                >
                    <DisplayText isPlaceholder={!selectedOption}>{selectedOption || placeholder}</DisplayText>
                    <ArrowImage
                        src={
                            isOpen
                                ? "assets/application/arrow-up.svg"
                                : "assets/application/arrow-down.svg"
                        }
                        alt={isOpen ? "up arrow" : "down arrow"}
                    />
                </DropdownHeader>
                {isOpen && (
                    <DropdownList isOpen={isOpen}>
                        <Input
                            id={`${id}-search`}
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            disabled={disabled}
                            style={{marginLeft: "5px", marginRight: "5px"}}
                        />
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option, index) => (
                                <DropdownItem
                                    key={index}
                                    onClick={() => handleOptionClick(option)}
                                    selected={option === value}
                                >
                                    <DisplayText>{option}</DisplayText>
                                </DropdownItem>
                            ))
                        ) : (
                            <DropdownItem>
                                <DisplayText>No results found</DisplayText>
                            </DropdownItem>
                        )}
                        <DropdownItem
                            onClick={() => handleOptionClick("Other")}
                        >
                            <DisplayText>{"Other"}</DisplayText>
                        </DropdownItem>
                    </DropdownList>
                )}
            </DropdownContainer>
            {isOtherSelected && (
                <div>
                    <Input
                        id={`${id}-other-input`}
                        type="text"
                        placeholder="Enter here"
                        onChange={handleOtherInputChange}
                        disabled={disabled}
                    />
                </div>
            )}
            <ErrorLabel isError={isError}>
                <img src="/assets/icons/error.svg" alt="error" />
                <ErrorText>{errorLabel}</ErrorText>
            </ErrorLabel>
        </>
    );
};

export default SelectSearchComponent;
