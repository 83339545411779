import Subjectivity from "../subjectivity";
import styled from "styled-components";
import SpaceGrotesk from "../space-grotesk";

type LoginProps = {
  isMobile?: boolean | null;
};

const StyledH2 = styled(SpaceGrotesk)<LoginProps>`
  text-shadow: none;
  font-size: ${(props) => (props.isMobile ? "24px" : "48px")};
  letter-spacing: ${(props) => (props.isMobile ? "-.5px" : "-1px")};
  margin-top: ${(props) => (props.isMobile ? "0" : "30px")};
  margin-bottom: 20px;
  color: #225C90;
`;

const LoginForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginInfo = styled.div`
  width: 70%;
  margin-bottom: 30px;
  font-weight: light;
`;

const NameInput = styled.div<{ isMobile?: boolean | null }>`
  display: ${({ isMobile }) => (isMobile ? 'block' : 'flex')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '0' : '5px')};
  width: 100%;
  max-width: 1000px;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-around' : 'space-around')};
  align-items: center;

  & > * {
    flex: ${({ isMobile }) => (isMobile ? '1 1 40%' : '1 1 40%')};
  }
`;

const ErrorBox = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 5px;
  color: #cb3101;
  background-color: #fdecea;
`;

const ErrorText = styled.span`
  height: 100%;
  display: inline;
  margin: auto 10px;
`;

export { StyledH2, LoginForm, LoginInfo, NameInput, ErrorBox, ErrorText };
