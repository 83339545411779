import React from "react";

import {
  Label,
  StyledInterLabel,
  StyledInterInput,
  InputBox,
  ErrorLabel,
  ErrorText,
  EyeImage,
  RequiredStarStyle,
} from "./index.styles";

interface Props {
  type?: string;
  id: string;
  title?: string;
  style?: any;
  isError?: boolean;
  errorLabel?: string;
  placeholder?: string;
  required?: boolean;
  value?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  requiredStar?: boolean;
  disabled?: boolean;
}

const toggleImg = (id: string) => {
  let password = document.getElementById(id) as HTMLInputElement;
  let passwordToggle = document.getElementById(
    id + "-error"
  ) as HTMLImageElement;
  if (password) {
    if (password.type === "password") {
      password.type = "text";
      passwordToggle.src = "/assets/icons/eye.svg";
      passwordToggle.style.top = "14px";
    } else {
      password.type = "password";
      passwordToggle.src = "/assets/icons/eye-visible.svg";
      passwordToggle.style.top = "12px";
    }
  }
};

const Input = ({
  id,
  type,
  title,
  style,
  isError,
  errorLabel,
  placeholder,
  required,
  requiredStar,
  value,
  onChange,
  disabled,
}: Props) => {
  return (
    <div style={{ marginTop: "30px", ...style }}>
      {requiredStar ? (
        <Label htmlFor={id}>
          <StyledInterLabel>
            {title}
            <RequiredStarStyle>*</RequiredStarStyle>
          </StyledInterLabel>
        </Label>
      ) : (
        <Label htmlFor={id}>
          <StyledInterLabel>{title}</StyledInterLabel>
        </Label>
      )}
      <StyledInterInput isError={isError} disabled={disabled}>
        <InputBox
          type={type}
          id={id}
          style={style}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <EyeImage
          src="/assets/icons/eye-visible.svg"
          alt="eye"
          type={type}
          id={id + "-error"}
          onClick={() => {
            toggleImg(id);
          }}
        />
      </StyledInterInput>
      <ErrorLabel isError={isError}>
        <img src="/assets/icons/error.svg" alt="error" />
        <ErrorText>{errorLabel}</ErrorText>
      </ErrorLabel>
    </div>
  );
};

export default Input;
