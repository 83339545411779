import Inter from "../inter";
import styled from "styled-components";
import InterSemiBold from "../inter-semi-bold";

type ErrorProps = {
  isError?: boolean | null;
  makeBig?: boolean | null;
  disabled?: boolean;
};

const Label = styled.label`
  display: block;
`;

const StyledInterLabel = styled(InterSemiBold)`
  font-size: 16px;
  text-align: left;

  color: var(--Default-Stroke, #282828);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledInterTextArea = styled(Inter)<ErrorProps>`
  font-size: 14px;
  text-align: left;
  color: #555555;
  border: ${(props) =>
    props.isError ? "1px solid #CB3101" : "1px solid #282828"};
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  flex: 1;
  &:focus-within {
    outline: ${(props) =>
      props.isError ? "1px solid #CB3101" : "1px solid #282828"};
  }
  ${(props) =>
    props.disabled &&
    `
    border: 1px solid rgba(40, 40, 40, 0.5);
    `}
`;

const StyledTextArea = styled.textarea<ErrorProps>`
  border-radius: 5px;
  border: none;
  background-color: #FFFFFF;
  padding: 10px 20px;
  color: #555;
  leading-trim: both;
  resize: none;
  text-edge: cap;
  &:focus {
    outline: none;
  }
  /* Body 2 (14px) */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  ${(props) => props.makeBig && `height: 10rem;`}
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    background-color: lightgray;
    cursor: not-allowed;
    color: black;
  `}
`;

const ErrorLabel = styled(Inter)<ErrorProps>`
  display: ${(props) => (props.isError ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #cb3101;
`;

const ErrorText = styled.div`
  height: 100%;
  display: inline;
  margin: auto 10px;
`;

const RequiredStarStyle = styled.span`
  color: #cb3101;
  margin-left: 0.25rem;
`;

export {
  StyledTextArea,
  ErrorLabel,
  ErrorText,
  Label,
  StyledInterLabel,
  StyledInterTextArea,
  RequiredStarStyle,
};
