import React, { useEffect, useState } from "react";
import {
  StyledSpaceGrotesk,
  StyledManrope,
  ButtonContainer,
  Image,
  BouncingImage,
  AcceptedDashboardContainer,
  AcceptedDashboardTextContainer,
  NoneButton,
  StyledLink,
} from "../index.styles";
import { useMobileDetect } from "../../../../hooks/useMobileDetect";
import { HomeButton } from "../home-button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import RSVPButton from "../../../button/rsvp-button";
import Modal from "../../../modal";
import Confetti from "react-confetti";
import RedirectButton from "../redirect-button";

type HomeProps = {
  isDrawerOpen?: boolean;
};

const AcceptedHomePageContent: React.FC<HomeProps> = React.memo(() => {
  const [catImg, setCatImg] = useState("none");

  const { app_started, app_completed, character_name, redux_character_name } =
    useSelector((state: RootState) => state.application);
  const { userFName, didRSVP, isRound2 } = useSelector(
    (state: RootState) => state.data
  );

  let name = userFName
    ? userFName.charAt(0).toUpperCase() + userFName.slice(1).toLowerCase()
    : "";

  // const dashboardHeader = !didRSVP
  //   ? "Thank You for Your Interest!"
  //   : name
  //   ? "Welcome, " + name + "!"
  //   : "Welcome back!";

  // const acceptedText =
  //   !didRSVP && isRound2 ? (
  //     <>
  //       The deadline to RSVP was January 18th @ 11:59pm ET. Thank you for your
  //       interest and we hope to see you next year!
  //     </>
  //   ) : !didRSVP && !isRound2 ? (
  //     <>
  //       The deadline to RSVP was January 13th @ 11:59pm ET. Thank you for your
  //       interest and we hope to see you next year!
  //     </>
  //   ) : (
  //     <>See you January 26-28, 2024 @ Myhal Centre!</>
  //   );

  const dashboardHeader = name ? "Welcome, " + name + "!" : "Welcome back!";

  const acceptedText = (
    <>
      Thank you so much for your interest in UofTHacks 12! See you all next year
      :) <br /> Meanwhile, you can follow us on Instagram (
      <StyledLink href="https://www.instagram.com/uofthacks" target="_blank">
        @uofthacks
      </StyledLink>
      ) to stay up to date with our latest announcements!
    </>
  );

  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("confetti") != "true") {
      localStorage.setItem("confetti", "true");
      setShowConfetti(true);
    }
  }, []);

  const showEasterEgg = () => {
    if (catImg === "none") {
      setCatImg("block");
    } else {
      setCatImg("none");
    }
  };

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "Escape") {
        showEasterEgg();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [catImg]);

  return (
    <>
      <Modal isOpen={showConfetti}>
        <Confetti
          run={showConfetti}
          numberOfPieces={1000}
          recycle={false}
          onConfettiComplete={() => {
            setShowConfetti(false);
          }}
        />
      </Modal>
      <ButtonContainer mobile={isMobile}>
        <AcceptedDashboardContainer mobile={isMobile} newHeader={didRSVP}>
          {didRSVP && !isMobile && (
            <>
              <Image
                src={"/assets/stars/small-star.svg"}
                alt={"Star"}
                style={{
                  top: "-25px",
                  left: "40%",
                }}
              />
              <Image
                src={"/assets/stars/star-pointy.svg"}
                alt={"Star"}
                style={{
                  top: "140px",
                  left: "-20px",
                }}
              />
            </>
          )}
          <AcceptedDashboardTextContainer>
            <StyledSpaceGrotesk mobile={isMobile}>
              {dashboardHeader}
            </StyledSpaceGrotesk>
            <StyledManrope>{acceptedText}</StyledManrope>
          </AcceptedDashboardTextContainer>
          {/* {!didRSVP && (
            <RSVPButtonContainer mobile={isMobile}>
              <RSVPButton
                text={"RSVP Here!"}
                colour="#F0A0C8"
                onClick={() => {
                  navigate("/rsvp");
                }}
                style={{
                  padding: "0.62rem",
                  gap: "0.5rem",
                }}
              />
            </RSVPButtonContainer>
          )} */}
          {catImg === "none" &&
            (didRSVP ? (
              <>
                <Image
                  src="/assets/stars/star.svg"
                  alt=""
                  mobile={isMobile}
                  style={{
                    cursor: "pointer",
                    top: "-50px",
                    right: "-40px",
                  }}
                  onClick={() => {
                    setShowConfetti(true);
                  }}
                />
                <Image
                  src="/assets/home-page/cloud.svg"
                  alt=""
                  mobile={isMobile}
                  width={200}
                  style={{
                    top: "130px",
                    right: "-30px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("https://www.youtube.com/watch?v=v6mfUh1sBMg")
                  }
                />
              </>
            ) : (
              <Image src="/assets/stars/star.svg" alt="" mobile={isMobile} />
            ))}
          <BouncingImage
            src="/assets/characters/cat.svg"
            alt=""
            display={catImg}
            mobile={isMobile}
          />
        </AcceptedDashboardContainer>
      </ButtonContainer>
      <ButtonContainer mobile={isMobile}>
        <HomeButton
          color="#FFD28E"
          title="My Passport"
          image="/assets/home-page/profile.svg"
          onClick={() => navigate("/profile")}
          disabled={!didRSVP}
        />
        <HomeButton
          color="#E3A1E3"
          title="FAQ / Contact Us"
          image="/assets/home-page/faq.svg"
          onClick={() => navigate("/faq")}
        />
      </ButtonContainer>

      {didRSVP ? (
        <>
          <ButtonContainer mobile={isMobile}>
            <HomeButton
              color="#C0F9EB"
              title="Hacker Schedule"
              image="/assets/home-page/schedule.svg"
              onClick={() => navigate("/schedule")}
            />
            <RedirectButton
              color={"#F0A0C8"}
              image={"/assets/home-page/hacker-pack.svg"}
              title={"Hacker Pack"}
              onClick={() => window.open("/hacker-pack.pdf", "_blank")}
            />
          </ButtonContainer>
          <ButtonContainer mobile={isMobile}>
            <RedirectButton
              color={"#CAB2FB"}
              image={"/assets/home-page/discord.svg"}
              title={"UofTHacks 12 Discord"}
              onClick={() =>
                window.open(
                  "https://discord.com/channels/1176251471898087564/1176253691892863006",
                  "_blank"
                )
              }
            />
            <RedirectButton
              color={"#E1EBFF"}
              image={"/assets/home-page/cog.svg"}
              title={"Hacking Your First Hackathon"}
              onClick={() => window.open("/hackathon-guide.pdf", "_blank")}
            />
          </ButtonContainer>
          <ButtonContainer mobile={isMobile}>
            <RedirectButton
              color={"#D4FFB2"}
              image={"/assets/home-page/bingo.svg"}
              title={"Hacker BINGO"}
              onClick={() => window.open("/hacker-bingo.pdf", "_blank")}
            />
            <NoneButton />
          </ButtonContainer>
        </>
      ) : (
        <ButtonContainer mobile={isMobile}>
          <HomeButton
            color="#FFD28E"
            title="Hacker Schedule"
            disabled
            image="/assets/home-page/schedule.svg"
          />
          <HomeButton
            color="#FFD28E"
            title="Hacker Pack"
            disabled
            image="/assets/home-page/schedule.svg"
          />
        </ButtonContainer>
      )}
    </>
  );
});

export { AcceptedHomePageContent };
