import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import DashboardContainer from "../dashboard-container";
import PasswordInput from "../password-input-box";
import Input from "../input-box";
import Button from "../button";
import styled from "styled-components";
import {API_BASE_URL, API_TYPE, API_VERSION} from "../../api/apiConfig";
import {ErrorBox, StyledH2} from "../login-form/index.styles";
import {useMobileDetect} from "../../hooks/useMobileDetect";
import LoadingCircleComponent from "../loading-component";
import {ErrorText} from "../input-box/index.styles";
import Inter from "../inter";

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    align-self: center;
    align-items: center;
    align-content: center;
`;
const MainContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    justify-content: center;
`;

const StyledImage = styled.img`
    margin: 2rem 0;
`;

const StyledInter = styled(Inter)`
    color: #282828;

    text-align: center;
    leading-trim: both;

    text-edge: cap;
    /* Body 1 (16px) */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2rem;
`;

const SuccessComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
`;

const AuthErrorContainer = styled.div`
    margin-top: 1rem;
    color: #ff0000;
    font-size: 14px;
    text-align: center;
`;

const ResetPassForm = () => {
    // this has two fields
    // enter new pass and re-enter new pass

    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isPasswordError, setPasswordError] = useState(false);
    const [isPasswordConfirmError, setPasswordConfirmError] = useState(false);
    const [authError, setAuthError] = useState(false);
    const [authErrorText, setAuthErrorText] = useState("");
    const [showNextPage, setShowNextPage] = useState(false);

    const isMobile = useMobileDetect();
    const navigate = useNavigate();
    const {token} = useParams();

    const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#?&])[A-Za-z\d@$!%#?&]{8,}$/;
    // useEffect(() => {
    //     setIsLoading(true)
    //     // Make fech to check if link is still valid
    //     // Route not implemented yet - TEMPORARY!
    //     fetch("${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/checkLink",
    //         {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${token}`,
    //             }
    //         }).then((res) => {
    //         setIsLoading(false);
    //         // if (res.ok) {
    //         //
    //         // } else {
    //         //     setAuthError(true);
    //         // }
    //     }).catch((err) => {
    //         setIsLoading(false);
    //         // setAuthError(true);
    //         setAuthErrorText("Something went wrong. Please try again.");
    //     })
    //
    // }, [])

    if (showNextPage) {
        return (
            <DashboardContainer>
                <StyledH2 isMobile={isMobile}>New Password Confirmed</StyledH2>
                <SuccessComponent>
                    <StyledImage src="/assets/icons/checked-circle.svg" alt="success"/>

                    <StyledInter>
                        Your password has been reset. Please login with your new password.
                    </StyledInter>

                    <Button
                        text="Back to login page"
                        colour="blue"
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                </SuccessComponent>
            </DashboardContainer>
        );
    } else if (authError) {
        return (
            <DashboardContainer>
                <StyledH2 isMobile={isMobile}>Reset Password Link Expired</StyledH2>
                <SuccessComponent>

                    <StyledInter>
                        Please try again. If this problem persists, please reach out to us on Instagram or at
                        contact@uofthacks.ca
                    </StyledInter>

                    <Button
                        text="Back to login page"
                        colour="blue"
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                </SuccessComponent>
            </DashboardContainer>
        )
    }


    const handleResetPass = () => {
        if (!password || !passwordConfirm) {
            // setAuthError(true);
            setAuthErrorText("Please fill out all fields.");
            return;
        }
        if (
            password &&
            password === passwordConfirm &&
            passwordRegex.test(passwordConfirm)
        ) {
            setIsLoading(true);
            fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/update_password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    hacker_password: password,
                    hacker_password_cmp: passwordConfirm
                }),
            })
                .then(async (res) => {
                    setIsLoading(false);
                    if (res.ok) {
                        setShowNextPage(true);
                    } else {
                        const responseBody = await res.json()
                        setAuthErrorText(responseBody.message || "Something went wrong. Please try again.")
                        // setAuthError(false);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    // setAuthError(true);
                    setAuthErrorText("Something went wrong. Please try again.");
                });
        }
        if (!password || !passwordRegex.test(password)) setPasswordError(true);
        else setPasswordError(false);

        if (!passwordConfirm) setPasswordConfirmError(true);
        else setPasswordConfirmError(false);
        if (password && passwordConfirm && password !== passwordConfirm) {
            // setAuthError(true);
            setAuthErrorText("Passwords do not match.");
            setPasswordError(true);
            setPasswordConfirmError(true);
        } else if (
            password &&
            passwordConfirm &&
            password === passwordConfirm &&
            !passwordRegex.test(password)
        ) {
            // setAuthError(true);
            setAuthErrorText(
                "Password does not meet all requirements or has invalid special characters. Make sure to follow the requirements below."
            );
            setPasswordError(true);
        }
    };

    return (
        <DashboardContainer>
            <LoadingCircleComponent isOpen={isLoading}/>
            <StyledH2 isMobile={isMobile}>Create New Password</StyledH2>

            <StyledForm
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                {authError ? (
                    <ErrorBox>
                        <img src="/assets/icons/error.svg" alt="error"/>
                        <ErrorText>{authErrorText}</ErrorText>
                    </ErrorBox>
                ) : null}
                <MainContainer>
                    <PasswordInput
                        id="password"
                        title="New Password"
                        type="password"
                        style={{color: "#282828"}}
                        placeholder="Enter your password"
                        value={password}
                        isError={isPasswordError}
                        onChange={(e) => {
                            setPassword(e.target.value.trim());
                            setPasswordError(false);
                        }}
                        required
                    />

                    <Input
                        id="password-confirm"
                        title="Confirm Password"
                        type="password"
                        style={{color: "#282828"}}
                        errorLabel="Sorry, your password does not match."
                        placeholder="Re-enter your password"
                        value={passwordConfirm}
                        isError={isPasswordConfirmError}
                        onChange={(e) => {
                            setPasswordConfirm(e.target.value);
                            setPasswordConfirmError(false);
                        }}
                        required
                    />
                </MainContainer>
            </StyledForm>

            <div
                style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: isMobile ? "column" : "row",
                    marginTop: "1rem",
                }}
            >

                <Button text="Set Password" colour="blue" onClick={handleResetPass}/>
            </div>
            
            {authErrorText && <AuthErrorContainer>{authErrorText}</AuthErrorContainer>}
        </DashboardContainer>
    );
};

export default ResetPassForm;
