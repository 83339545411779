const email =
    '<a href="mailto:official@uofthacks.com" target="_blank" rel="noopener" style="color: #225C90; font-weight: 700">official@uofthacks.com</a>';
const covidPolicy =
    '<a href="https://ehs.utoronto.ca/covid-19-information/" target="_blank" rel="noopener" style="color: #225C90; font-weight: 700">University of Toronto\'s</a>';
const instagram =
    '<a href="https://www.instagram.com/uofthacks/" target="_blank" rel="noopener" style="color: #225C90; font-weight: 700">Instagram</a>';

const faqs = [
    {
        question: "What is UofTHacks?",
        answer: (
            <p>
                UofTHacks is an annual hackathon organized by students at the University
                of Toronto. It brings together over 500+ tech enthusiasts, innovators,
                and creators from various backgrounds to collaborate and build
                innovative projects in 36 hours.
            </p>
        ),
    },
    {
        question: "When and where is UofTHacks held?",
        answer: (
            <p>
                UofTHacks 12 is set to take place from January 17th to January 19th,
                2025! This year’s event will be hosted entirely in-person at the Myhal
                Centre! The exact schedule of all the ceremonies, activities, and
                workshops will be released in the week leading up to the event!
            </p>
        ),
    },
    {
        question: "Who can participate?",
        answer: (
            <p>
                High school and university students can join, no matter their experience.
                Whether you're a coding expert or a beginner, everyone's welcome. We will
                provide workshops and Mentorship to help you build your project!
            </p>
        ),
    },
    {
        question: "Can I attend if I live far away?",
        answer: (
            <p>
                Yes! If you're registered, you're all set. Just get yourself to Toronto
                and bring any essentials for an overnight stay. We'll have places for you
                to rest, but unfortunately, we will not be providing any travel
                reimbursements. If you're coming from outside Canada, make sure to follow
                the travel rules. Please note: If you will be attending from outside of
                Canada, you need to ensure you are following the Government of Canada's
                travel policies. Please contact us by sending a direct message on{" "}
                <a href="https://www.instagram.com/uofthacks/" target="_blank" rel="noopener" style={{ color: "#225C90", fontWeight: 700 }}>
                    Instagram
                </a>{" "}
                or at{" "}
                <a href="mailto:official@uofthacks.com" target="_blank" rel="noopener" style={{ color: "#225C90", fontWeight: 700 }}>
                    official@uofthacks.com
                </a>{" "}
                for more information. We'd be happy to help!
            </p>
        ),
    },
    {
        question: "Can I join virtually?",
        answer: (
            <p>
                Unfortunately, we will not have any virtual aspects to this year's event.
                All members of your team must be in-person to be considered for judging.
            </p>
        ),
    },
    {
        question: "How many hackers per team?",
        answer: (
            <p>
                Each team can have up to 4 hackers. As long as they are a registered
                hacker with UofTHacks, there are no restrictions on who can be on your
                team. However, there may be challenges that have team member requirements
                to be eligible for specific prizes. More details will be announced during
                opening ceremonies.
            </p>
        ),
    },
    {
        question: "What are your COVID-19 and other respiratory illness regulations?",
        answer: (
            <p>
                Our policy will reflect the{" "}
                <a href="https://ehs.utoronto.ca/covid-19-information/" target="_blank" rel="noopener" style={{ color: "#225C90", fontWeight: 700 }}>
                    University of Toronto's
                </a>{" "}
                protocols. The focus will be to ensure the event is a safe environment
                for all participants. We will have hand sanitizer stations and
                disinfectant wipes throughout the venue.
            </p>
        ),
    },
    {
        question: "What do Mentors do?",
        answer:
            "Mentors are your key resources for guidance. They provide valuable industry knowledge, offer professional career advice, and assist with the technical challenges you may face during your project. Their role is to support and enhance your experience with both expertise and insight.",
    },
    {
        question: "What are the requirements to be a Mentor?",
        answer:
            "To be a Mentor, you must be at least 18 years old and have relevant experience in the tech industry. While previous internship experience is not required, it is considered an advantage. Mentors should possess strong technical knowledge and a commitment to supporting participants throughout the event.",
    },
    {
        question: "What do Volunteers do?",
        answer:
            "Volunteers help make sure the event runs smoothly! They assist with setup, logistics, and anything else that needs to be done to keep the event awesome.",
    },
    {
        question: "What are the requirements to be a Volunteer?",
        answer:
            "You just need to be enthusiastic and ready to help! No specific skills are needed, just a good attitude and a willingness to pitch in.",
    },
    {
        question:
            "Are sleeping areas or supplies available for participants at UofTHacks 2025?",
        answer:
            "Unfortunately, due to university regulations, we are unable to provide or allow sleeping accommodations on-site this year. However, the venue will be open throughout the event so you can continue hacking, take breaks, and grab refreshments whenever you need! Our team will be around to help out, so don’t hesitate to stop by the help desk for anything you might need to stay energized.",
    },
    {
        question: "What is MLH?",
        answer: (
            <p>
                Major League Hacking (MLH) is the official student hackathon league. Each year, we power over 300 weekend-long invention competitions that inspire innovation, cultivate communities, and teach computer science skills to more than 500,000 developers around the world. MLH is an engaged and passionate maker community, consisting of the next generation of technology leaders and entrepreneurs. You can read the{" "}
                <a href="http://hackp.ac/coc" target="_blank" rel="noopener" style={{ color: "#225C90", fontWeight: 700 }}>
                    Code of Conduct
                </a>{" "}
                for more information.
            </p>
        ),
    },
    {
        question: "I still have a question…",
        answer: (
            <p>
                We’d be happy to help! You can send us a direct message on Instagram or
                Twitter - @uofthacks. Alternatively, you can send the inquiry over to{" "}
                <a href="mailto:official@uofthacks.com" target="_blank" rel="noopener" style={{ color: "#225C90", fontWeight: 700 }}>
                    official@uofthacks.com
                </a>{" "} and
                we’ll get back to you as soon as possible.
            </p>
        ),
    },
];

export default faqs;
