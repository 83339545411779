import React, { useEffect } from "react";
import { FileIcon } from "./file-icon";
import {
  StyledFileUpload,
  StyledInterFileUpload,
  StyledInterLabel,
  StyledLink,
  RequiredStarStyle,
  RemoveUploadContainer,
  StyledContainer,
} from "./index.styles";
import { ErrorBox } from "../login-form/index.styles";
import { ErrorText } from "../input-box/index.styles";
import LoadingCircleComponent from "../loading-component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  RootState,
  setAppResume,
  setAppResumeSubmitted,
} from "../../redux/store";

interface Props {
  text: string;
  title: string;
  image?: string;
  notTransparent?: boolean;
  colour?: string;
  style?: any;
  onClick?: () => void;
  editImage?: boolean;
  isMobile?: boolean | null;
  requiredStar?: boolean;
  disabled?: boolean;
}

const FileUpload = ({
                      colour,
                      onClick,
                      text,
                      style,
                      notTransparent,
                      isMobile,
                      title,
                      requiredStar,
                      disabled,
                    }: Props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [fileError, setSelectedFileError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const { app_resume, app_completed } = useSelector(
      (state: RootState) => state.application
  );

  useEffect(() => {
    setSelectedFile(app_resume);
  }, [app_resume]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files?.[0];
    if (file && file.type === "application/pdf" && file.size <= 1000000) {
      dispatch(setAppResume(file));
      setSelectedFile(file);
      setSelectedFileError(false);
      setIsLoading(false);
      dispatch(setAppResumeSubmitted(true));
    } else {
      dispatch(setAppResume(null));
      setSelectedFile(null);
      setSelectedFileError(true);
      setIsLoading(false);
      dispatch(setAppResumeSubmitted(false));
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleViewClick = () => {
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      window.open(url, "_blank");
      URL.revokeObjectURL(url);
    }
  };

  return (
      <>
        {requiredStar ? (
            <>
              <StyledInterLabel>
                {title}
                <RequiredStarStyle>*</RequiredStarStyle>
              </StyledInterLabel>
            </>
        ) : (
            <StyledInterLabel>{title}</StyledInterLabel>
        )}
        <StyledContainer>
          <LoadingCircleComponent isOpen={isLoading} />
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <StyledFileUpload
                notTransparent={notTransparent}
                colour={colour}
                onClick={disabled ? () => {} : onClick}
                style={style}
                onMouseEnter={disabled ? () => {} : () => setIsHovered(true)}
                onMouseLeave={disabled ? () => {} : () => setIsHovered(false)}
                isMobile={isMobile}
                htmlFor="file-upload"
                disabled={disabled}
            >
              <FileIcon isHovered={disabled ? false : isHovered} />
              <StyledInterFileUpload style={{ fontSize: 20}}>{text}</StyledInterFileUpload>
              <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  accept="application/pdf"
                  style={{ display: "none" }}
                  disabled={disabled}
                  ref={fileInputRef}
              />
            </StyledFileUpload>
          </div>

          <StyledInterFileUpload style={{ color: "black"}}>
            {"PDF (max size 1 MB)"}
          </StyledInterFileUpload>
          {fileError && (
              <ErrorBox>
                <img src="/assets/icons/error.svg" alt="error" />
                <ErrorText>Please upload a .pdf file less than 1MB</ErrorText>
              </ErrorBox>
          )}
        </StyledContainer>
        <StyledInterFileUpload style={{color: "#7B7C7F"}}>
          {selectedFile ? (
              <StyledInterFileUpload>
                <StyledLink onClick={handleViewClick}>
                  {selectedFile.name}
                </StyledLink>
                {!app_completed && (
                    <RemoveUploadContainer
                        onClick={() => {
                          dispatch(setAppResume(null));
                          setSelectedFile(null);
                          dispatch(setAppResumeSubmitted(false));
                          if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                          }
                        }}
                    >
                      x
                    </RemoveUploadContainer>
                )}
              </StyledInterFileUpload>
          ) : (
              "No file selected"
          )}
        </StyledInterFileUpload>
      </>
  );
};

export default FileUpload;