import React, { useEffect, useState } from "react";
import styled from "styled-components";

type FileIconProps = {
  isHovered: boolean;
  uploaded?: boolean;
};

const Wrapper = styled.div`
  height: 24px;
  width: 24px;
  margin: 10px;
`;

const Icon = styled.svg<FileIconProps>`
  width: 100%;
  height: 100%;
  // cursor: pointer;
  fill: ${(props) =>
    props.isHovered && !props.uploaded ? "#f9f9f9" : "#2850A0"};
  transition: 0.3s ease-out all;
`;

export const FileIcon = ({ isHovered, uploaded }: FileIconProps) => {
  const [hovered, setHovered] = useState(true);
  useEffect(() => {
    setHovered(isHovered);
  }, [isHovered]);

  return (
    <Wrapper>
      {!uploaded && (
        <Icon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          isHovered={hovered}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22V13M12 13L15.5 16.5M12 13L8.5 16.5" stroke="#225C90" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M20 17.6073C21.4937 17.0221 23 15.6889 23 13C23 9 19.6667 8 18 8C18 6 18 2 12 2C6 2 6 6 6 8C4.33333 8 1 9 1 13C1 15.6889 2.50628 17.0221 4 17.6073"
                stroke="#225C90" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </Icon>
      )}
      {uploaded && (
          <Icon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              isHovered={hovered}
          >
            <path
                d="M11 20H6.5C4.98333 20 3.6875 19.475 2.6125 18.425C1.5375 17.375 1 16.0917 1 14.575C1 13.275 1.39167 12.1167 2.175 11.1C2.95833 10.0833 3.98333 9.43333 5.25 9.15C5.66667 7.61667 6.5 6.375 7.75 5.425C9 4.475 10.4167 4 12 4C13.95 4 15.6042 4.67917 16.9625 6.0375C18.3208 7.39583 19 9.05 19 11C20.15 11.1333 21.1042 11.6292 21.8625 12.4875C22.6208 13.3458 23 14.35 23 15.5C23 16.75 22.5625 17.8125 21.6875 18.6875C20.8125 19.5625 19.75 20 18.5 20H13V12.85L14.6 14.4L16 13L12 9L8 13L9.4 14.4L11 12.85V20Z" />
        </Icon>
      )}
    </Wrapper>
  );
};
