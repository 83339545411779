export const programOptions = [
    "Computer science, computer engineering, or software engineering",
    "Another engineering discipline (such as civil, electrical, mechanical, etc.)",
    "Information systems, information technology, or system administration",
    "A natural science (such as biology, chemistry, physics, etc.)",
    "Mathematics or statistics",
    "Web development or web design",
    "Business discipline (such as accounting, finance, marketing, etc.)",
    "Humanities discipline (such as literature, history, philosophy, etc.)",
    "Social science (such as anthropology, psychology, political science, etc.)",
    "Fine arts or performing arts (such as graphic design, music, studio art, etc.)",
    "Health science (such as nursing, pharmacy, radiology, etc.)",
    "Undecided / No Declared Major",
    "My school does not offer majors / primary areas of study",
    "High School",
    "Prefer not to answer"
];