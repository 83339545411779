import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    RootState,
    setAppNumberHackathon,
    setAppElevatorPitch,
    setAppLearningProcess,
    setAppUofTHacksExp,
    setAppUofTHacksSuggestions, setAppOptional,
} from "../../redux/store";
import {ApplicationPage, HackathonInputContainer} from "./index.styles";
import InputOption from "../input-option";
import Input from "../input-box";
import TextArea from "../input-textarea";

const AppPage3 = React.memo(
    ({
         isHackathonAttendedError,
         isElevatorPitchError,
         isUofTHacksExpError,
         isLearningProcessError,
         isOptionalError,
     }: {
        isHackathonAttendedError: boolean;
        isElevatorPitchError: boolean;
        isUofTHacksExpError: boolean;
        isLearningProcessError: boolean;
        isOptionalError: boolean;
    }) => {
        const {
            app_page,
            app_hackathon_attended,
            app_number_hackathon,
            app_elevator_pitch,
            app_uofthacks_exp,
            app_learning_process,
            app_uofthacks_suggestions,
            app_optional,
            app_completed,
        } = useSelector((state: RootState) => state.application);

        const [showHackathonInput, setShowHackathonInput] = useState(
            !app_hackathon_attended
        );
        useEffect(() => {
            setShowHackathonInput(app_hackathon_attended);
        }, [app_hackathon_attended]);

        const dispatch = useDispatch();
        return (
            <ApplicationPage id="page-3" show={app_page == 3}>
                <InputOption
                    id="atd-hackathon"
                    title="Have you been to a hackathon before?"
                    type="radio"
                    errorLabel="Please select an option."
                    options={[
                        new Map<string, any>([
                            ["value", "yes"],
                            ["text", "Yes"],
                            ["checked", app_hackathon_attended],
                        ]),
                        new Map<string, any>([
                            ["value", "no"],
                            ["text", "No"],
                            ["checked", !app_hackathon_attended],
                        ]),
                    ]}
                    //required
                    requiredLabelStar
                    disabled={app_completed}
                />

                <HackathonInputContainer show={showHackathonInput}>
                    <Input
                        id="atd-hackathon-num"
                        title="How many hackathons have you attended?"
                        errorLabel="Please enter in the number of hackathons you've attended."
                        placeholder="e.g. 2"
                        value={app_number_hackathon}
                        isError={isHackathonAttendedError}
                        onChange={(e) => {
                            // accept only numbers
                            // accept backspace
                            if (e.target.value === "") {
                                dispatch(setAppNumberHackathon(""));
                                return;
                            }
                            if (isNaN(Number(e.target.value))) return;
                            // accept only positive numbers
                            if (Number(e.target.value) < 0) return;
                            dispatch(setAppNumberHackathon(Number(e.target.value)));
                        }}
                        //required
                        requiredStar
                        disabled={app_completed}
                    />
                </HackathonInputContainer>

                <TextArea
                    id="pitch"
                    title="Give us your elevator pitch — a 10 Word Tagline about Yourself (Slogan/Pitch)"
                    errorLabel="Please fill out this question."
                    placeholder="max. 10 words"
                    isError={isElevatorPitchError}
                    maxWords={10}
                    value={app_elevator_pitch}
                    maxLength={100}
                    onChange={(e) => {
                        dispatch(setAppElevatorPitch(e.target.value));
                    }}
                    //required
                    requiredStar
                    disabled={app_completed}
                />
                <TextArea
                    id="purpose"
                    title="Describe a situation in which you learned something new quickly by looking through a different lens. How did you approach the learning process? (1000 characters)"
                    errorLabel="Please fill out this question."
                    placeholder="max. 1000 characters"
                    value={app_uofthacks_exp}
                    onChange={(e) => {
                        dispatch(setAppUofTHacksExp(e.target.value));
                    }}
                    isError={isUofTHacksExpError}
                    maxLength={1000}
                    requiredStar
                    makeBig
                    disabled={app_completed}
                />
                <TextArea
                    id="learned"
                    title="You can bring any fictional character to brainstorm with you during UofTHacks. Who would you bring, and how would they inspire your approach? (500 Characters)"
                    errorLabel="Please fill out this question."
                    placeholder="max. 500 characters"
                    isError={isLearningProcessError}
                    maxLength={500}
                    value={app_learning_process}
                    onChange={(e) => {
                        dispatch(setAppLearningProcess(e.target.value));
                    }}
                    requiredStar
                    makeBig
                    disabled={app_completed}
                />
                <TextArea
                    id="optional"
                    title="Optional: What is important for you to see at UofTHacks? Do you have any suggestions for games, workshops, and other activities? (500 characters)"
                    errorLabel="Please fill out this question."
                    placeholder="max. 500 characters"
                    isError={isOptionalError}
                    maxLength={500}
                    value={app_optional}
                    onChange={(e) => {
                        dispatch(setAppOptional(e.target.value));
                    }}
                    makeBig
                    disabled={app_completed}
                />
            </ApplicationPage>
        );
    }
);

export default AppPage3;
