import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    setAppPronouns,
    setAppFirstName,
    setAppLastName,
    setAppEmail,
    setAppPreferredName,
    setAppCountry,
    setAppCity,
    setAppSchool,
    setAppMajor,
    setAppYear,
    setAppAge,
    setAppEthnicity,
    setAppGender,
    RootState,
} from "../../redux/store";
import {ApplicationPage, FlexContainer} from "./index.styles";
import SelectComponent from "../input-select";
import Input from "../input-box";
import {countryOptions} from "./dropdown-options/country";
import {pronounsOptions} from "./dropdown-options/pronouns";
import AgeSelectComponent from "../input-select/input-age-select";
import {ageObject, ageOptions} from "./dropdown-options/age";
import {yearOptions} from "./dropdown-options/year-of-study";
import {ethnicityOptions} from "./dropdown-options/ethnicity";
import {genderOptions} from "./dropdown-options/gender";
import {schoolOptions} from "./dropdown-options/school";
import SelectSearchComponent from "../input-search-select";
import {useNavigate} from "react-router-dom";
import {useMobileDetect} from "../../hooks/useMobileDetect";
import {programOptions} from "./dropdown-options/programs";

const AppPage1 = React.memo(
    ({
         isCityError,
         isGenderError,
         isPronounsError,
         isCountryError,
         isGenderTextError,
         isPronounsTextError,
         isCountryTextError,
         isAgeError,
         isMajorError,
         isSchoolError,
         isSchoolTextError,
         isMajorTextError,
         isStudyYearError,
         isStudyYearTextError,
         isEthnicityError,
         isEthnicityTextError,
         isAgeTextError,
         isPreferredNameError,
     }: {
        isCityError: boolean;
        isGenderError: boolean;
        isPronounsError: boolean;
        isCountryError: boolean;
        isGenderTextError: boolean;
        isPronounsTextError: boolean;
        isCountryTextError: boolean;
        isAgeError: boolean;
        isMajorError: boolean;
        isSchoolError: boolean;
        isSchoolTextError: boolean;
        isMajorTextError: boolean;
        isStudyYearError: boolean;
        isStudyYearTextError: boolean;
        isEthnicityError: boolean;
        isEthnicityTextError: boolean;
        isAgeTextError: boolean;
        isPreferredNameError: boolean;
    }) => {
        const {
            app_gender,
            app_page,
            app_pronouns,
            app_firstName,
            app_lastName,
            app_email,
            app_preferredName,
            app_country,
            app_city,
            app_school,
            app_major,
            app_age,
            app_ethnicity,
            app_discord,
            app_completed,
            app_year,
        } = useSelector((state: RootState) => state.application);
        const dispatch = useDispatch();


        const [receivedUserInfo, setReceivedUserInfo] = useState(false);

        useEffect(() => {
            if (app_firstName && app_lastName && app_email) {
                setReceivedUserInfo(true)
            }
        }, []);
        const isMobile = useMobileDetect()

        return (
            <ApplicationPage id="page-1" show={app_page == 1}>
                <FlexContainer isMobile={isMobile}>
                    <Input
                        id="firstName"
                        title="First Name"
                        placeholder="Enter your first name"
                        type="text"
                        value={app_firstName}
                        onChange={(e) => {
                            dispatch(setAppFirstName(e.target.value));
                        }}
                        requiredStar
                        disabled={app_completed || receivedUserInfo}
                    />

                    <Input
                        id="lastName"
                        title="Last Name"
                        placeholder="Enter your last name"
                        type="text"
                        value={app_lastName}
                        onChange={(e) => {
                            dispatch(setAppLastName(e.target.value));
                        }}
                        requiredStar
                        disabled={app_completed || receivedUserInfo}
                    />
                </FlexContainer>

                <Input
                    id="email"
                    title="What is your email?"
                    type="text"
                    errorLabel="Please enter your email."
                    placeholder="e.g. johndoe@gmail.com"
                    value={app_email}
                    onChange={(e) => {
                        dispatch(setAppEmail(e.target.value));
                    }}
                    requiredStar
                    disabled={app_completed || receivedUserInfo}
                />

                <Input
                    id="preferredName"
                    title="Preferred Name"
                    type="text"
                    errorLabel="Please enter your preferred name."
                    placeholder="e.g. “johndoe”"
                    value={app_preferredName}
                    isError={isPreferredNameError}
                    onChange={(e) => {
                        dispatch(setAppPreferredName(e.target.value));
                    }}
                    requiredStar
                    disabled={app_completed}
                />

                <SelectComponent
                    id="country"
                    title="Which country will you be travelling from?"
                    options={countryOptions}
                    errorLabel="Please select your country."
                    isError={isCountryError}
                    isTextError={isCountryTextError}
                    value={app_country}
                    onChange={(val: string) => {
                        dispatch(setAppCountry(val));
                    }}
                    requiredStar
                    disabled={app_completed}
                />
                <Input
                    id="city"
                    title="Which city are you currently living in?"
                    type="text"
                    errorLabel="Please enter your city."
                    placeholder="e.g. “Toronto”"
                    value={app_city}
                    isError={isCityError}
                    onChange={(e) => {
                        dispatch(setAppCity(e.target.value));
                    }}
                    requiredStar
                    disabled={app_completed}
                />

                <SelectSearchComponent
                    id="school"
                    title="What school are you currently attending?"
                    options={schoolOptions}
                    errorLabel="Please enter your school."
                    placeholder="e.g. “University of Toronto”"
                    value={app_school}
                    onChange={(val) => {
                        dispatch(setAppSchool(val));
                    }}
                    isError={isSchoolError}
                    isTextError={isSchoolTextError}
                    requiredStar
                    disabled={app_completed}
                />

                {/*<Input*/}
                {/*    id="school"*/}
                {/*    title="What school are you currently attending?"*/}
                {/*    type="text"*/}
                {/*    errorLabel="Please enter your school."*/}
                {/*    placeholder="e.g. “University of Toronto”"*/}
                {/*    value={app_school}*/}
                {/*    //isError={isSchoolError}*/}
                {/*    onChange={(e) => {*/}
                {/*        dispatch(setAppSchool(e.target.value));*/}
                {/*    }}*/}
                {/*    requiredStar*/}
                {/*    disabled={app_completed}*/}
                {/*/>*/}
                <SelectComponent
                    id="major"
                    title="What is your major? (If you are in High School, please select 'High School')"
                    options={programOptions}
                    errorLabel="Please enter your program."
                    placeholder="e.g. “Computer Engineering”"
                    value={app_major}
                    isError={isMajorError}
                    isTextError={isMajorTextError}
                    onChange={(val: string) => {
                        dispatch(setAppMajor(val));
                    }}
                    requiredStar
                    disabled={app_completed}
                />

                <SelectComponent
                    id="year"
                    title="What is your current year of study?"
                    errorLabel="Please select your current year of study."
                    options={yearOptions}
                    value={app_year}
                    onChange={(val: string) => {
                        dispatch(setAppYear(val));
                    }}
                    isError={isStudyYearError}
                    isTextError={isStudyYearTextError}
                    requiredStar
                    disabled={app_completed}
                />

                <SelectComponent
                    id="genders"
                    title="What is your gender?"
                    errorLabel="Please select your gender."
                    isError={isGenderError}
                    isTextError={isGenderTextError}
                    options={genderOptions}
                    value={app_gender}
                    onChange={(val: string) => {
                        dispatch(setAppGender(val));
                    }}
                    requiredStar
                    disabled={app_completed}
                    hideOther={true}
                />

                <SelectComponent
                    id="pronouns"
                    title="What are your preferred pronouns?"
                    errorLabel="Please select your pronouns."
                    isError={isPronounsError}
                    isTextError={isPronounsTextError}
                    options={pronounsOptions}
                    value={app_pronouns}
                    onChange={(val: string) => {
                        dispatch(setAppPronouns(val));
                    }}
                    requiredStar
                    disabled={app_completed}
                />

                <SelectComponent
                    id="age"
                    title="What is your age?"
                    errorLabel="Please enter your age."
                    options={ageOptions}
                    value={app_age}
                    onChange={(val: string) => {
                        dispatch(setAppAge(val));
                    }}
                    isError={isAgeError}
                    isTextError={isAgeTextError}
                    requiredStar
                    disabled={app_completed}
                    hideOther={true}
                />

                {/*<Input*/}
                {/*  id="age"*/}
                {/*  title="What is your age?"*/}
                {/*  type="text"*/}
                {/*  errorLabel="Please enter your age."*/}
                {/*  placeholder="e.g. 21"*/}
                {/*  value={app_age}*/}
                {/*  //isError={isAgeError}*/}
                {/*  onChange={(e) => {*/}
                {/*    dispatch(setAppAge(e.target.value));*/}
                {/*  }}*/}
                {/*  requiredStar*/}
                {/*  disabled={app_completed}*/}
                {/*/>*/}

                <SelectComponent
                    id="ethnicity"
                    title="What is your ethnicity/race?"
                    errorLabel="Please select your ethnicity/race"
                    options={ethnicityOptions}
                    value={app_ethnicity}
                    onChange={(val: string) => {
                        dispatch(setAppEthnicity(val));
                    }}
                    isError={isEthnicityError}
                    isTextError={isEthnicityTextError}
                    requiredStar
                    disabled={app_completed}
                />
            </ApplicationPage>
        );
    }
);

export default AppPage1;
