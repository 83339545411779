import React from "react";
import { useNavigate } from "react-router-dom";
import { useMobileDetect } from "../../hooks/useMobileDetect";
import Button from "../../components/button";
import { DashboardContents, StyledH2 } from "./index.styles";
import DashboardContainer from "../../components/dashboard-container";

const EmailSentComponent = () => {
  const navigate = useNavigate();
  const isMobile = useMobileDetect();

  return (
    <DashboardContainer mobile={isMobile}>
      <DashboardContents>
        <StyledH2 isMobile={isMobile}>Email Sent!</StyledH2>
        <img
          style={{ pointerEvents: "none" }}
          src="assets/home-page/email.svg"
          alt="email"
        />
        <p
          style={{
            maxWidth: "90vw",
            lineHeight: "2rem",
            textAlign: "center",
            overflowWrap: "break-word",
          }}
        >
          Please check all folders including spam!
          <br />
          If an account is associated this email, we have sent you instructions on how to reset your password.
        </p>
        <Button
          text="Back to login page"
          colour="blue"
          onClick={() => {
            navigate("/login");
          }}
        />
      </DashboardContents>
    </DashboardContainer>
  );
};

export default EmailSentComponent;
