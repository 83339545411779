import Inter from "../inter";
import InterSemiBold from "../inter-semi-bold";
import styled from "styled-components";
// import { useMobileDetect } from "../../hooks/useMobileDetect";

const StyledInterFileUpload = styled(Inter)`
  text-align: left;
  leading-trim: both;
  font-size: 14px;
  text-edge: cap;
  /* Button */
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  display: flex;
  color: #225C90;
`;

type ButtonProps = {
  colour?: string;
  notTransparent?: boolean;
  isMobile?: boolean | null;
  disabled?: boolean;
};

const StyledContainer = styled.div`
  margin-top: 5px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid black;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledFileUpload = styled.label<ButtonProps>`
  background-color: transparent;
  //color: #2850a0;
  text-decoration: none;
  width: 200px;
  padding-right: 10px;
  height: 50px;
  //border: 1px solid #282828;
  border-radius: 10px;
  transition: ease-in 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    background-color: lightgray;
    cursor: not-allowed;
    &:hover {
      opacity: 0.5;
      background-color: lightgray;
      cursor: not-allowed;
      color: #2850a0;
    }
  `}
`;

const StyledInterLabel = styled(InterSemiBold)`
  font-size: 16px;
  text-align: left;

  color: var(--Default-Stroke, #282828);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledLink = styled.a`
  color: #007d7d;
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
`;

const RequiredStarStyle = styled.span`
  color: #cb3101;
  margin-left: 0.25rem;
`;

const RemoveUploadContainer = styled.div`
  margin-left: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  color: #282828;
  &:hover {
    color: #cb3101;
  }
`;

export {
  StyledContainer,
  StyledInterFileUpload,
  StyledFileUpload,
  StyledInterLabel,
  StyledLink,
  RequiredStarStyle,
  RemoveUploadContainer,
};
