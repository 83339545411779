import { QuizQuestion } from "./index.styles";

const questions = [
  {
    //Question 1
    question: (
      <QuizQuestion>
        Let’s start hacking! When working on a project, what’s your preferred working style?
      </QuizQuestion>
    ),
    options: [
      "A. I'm a reliable team player and always getting things done!",
      "B. I like to take charge and lead the team to victory!",
      "C. I am idea-oriented and love to innovate!",
      "D. I seek insights from everyone, ask questions, and collaborate!",
    ],
    background: "#FFFFFF",
    selectedBackground: "#0B3962",
    border: "#0B3962",
  },
  {
    //Question 2
    question: (
      <QuizQuestion>
        You are starting to get sleepy and tired. Your team decides to go out
        for coffee but can't decide! What do you do?
      </QuizQuestion>
    ),
    options: [
      "A. I consider everyone's preferences, and suggest a café known for its customizable coffee options.",
      "B. I analyze all the options, and consider important factors such as quality of coffee, and distance from the venue.",
      "C. I take charge and suggest a nearby café known for its fast service.",
      "D. I listen to everyone's preferences and recommend a well-known coffee place that has great service.",
    ],
    background: "#FFFFFF",
    selectedBackground: "#0B3962",
    border: "#0B3962",
  },
  {
    //Question 3
    question: (
      <QuizQuestion>
        Oh no! Your demo isn't working and there's 30 minutes left until the
        deadline! <br /> How do you handle these challenges?
      </QuizQuestion>
    ),
    options: [
      "A. I collaborate with my team, and seek innovative solutions to troubleshoot and fix the code!",
      "B. I quickly analyze the problem and logically debug the code to get it working.",
      "C. I collaborate with my team, and work together to create an alternate solution that can be demoed!",
      "D. I assess the problem meticulously, and break it down into smaller components to try to fix it!",
    ],
    background: "#FFFFFF",
    selectedBackground: "#0B3962",
    border: "#0B3962",
  },
  {
    //Question 4
    question: (
      <QuizQuestion>
        It’s mini-event time! Time for some cup stacking fun! What is your strategy?
      </QuizQuestion>
    ),
    options: [
      "A. Wing it!",
      "B. Strategize with my team. ",
      "C. Look at other teams to see what they are doing right or wrong.",
      "D. Search up strategies online.",
    ],
    background: "#FFFFFF",
    selectedBackground: "#0B3962",
    border: "#0B3962",
  },
  {
    //Question 5
    question: (
      <QuizQuestion>
        You’re about to start your project, and you realize that using a new tool or programming language could be really beneficial, but you’re unfamiliar with it. How do you approach learning and incorporating it into your project?
      </QuizQuestion>
    ),
    options: [
      "A. I  start getting familiar with the new tool or programming language using online resources.",
      "B. I’d consult mentors for advice on tackling the learning curve and time constraints.",
      "C. I’d ask my team members or someone I know who is familiar with the tool or language for help, so we can \n" +
      "learn quickly and keep the project moving.",
      "D. Knowing the time constraints, I would stick to tools and programs I know already.",
    ],
    background: "#FFFFFF",
    selectedBackground: "#0B3962",
    border: "#0B3962",
  },
  {
    //Question 6
    question: (
      <QuizQuestion>
        It's time to present and show off your project! How do you deliver your
        project to the judges?
      </QuizQuestion>
    ),
    options: [
      "I'm extremely expressive and full of enthusiasm! I persuade the judges that my project is innovative and solves their challenge!",
      "I answer all their questions and dive deep into the logic and decision making our project uses!",
      "I explain each complex solution very logically and clearly! I use a calm and methodical approach to explain the concepts!",
      "I focus on conveying design concepts in a way that's relatable to the judges! I prioritize clear and friendly communication!",
    ],
    background: "#FFFFFF",
    selectedBackground: "#0B3962",
    border: "#0B3962",
  },
];

export default questions;
