export const ageOptions = [
  "12 and under",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30 and older"
];

export const ageObject = {
  "12 and under": 12,
  "13": 13,
  "14": 14,
  "15": 15,
  "16": 16,
  "17": 17,
  "18": 18,
  "19": 19,
  "20": 20,
  "21": 21,
  "22": 22,
  "23": 23,
  "24": 24,
  "25": 25,
  "26": 26,
  "27": 27,
  "28": 28,
  "29": 29,
  "30 and older": 30,
};

export const reversedAgeObject = Object.keys(ageObject).reduce(
  (obj: { [key: number]: string }, key) => {
    obj[ageObject[key as keyof typeof ageObject]] = key;
    return obj;
  },
  {}
);
