import { useSelector } from "react-redux";
import QuizComponent from "../../components/quiz";
import Results from "../../components/quiz/results/results";
import {
  RootState,
  setCharacterName,
  setQuizQ1,
  setQuizQ2,
  setQuizQ3,
  setQuizQ4,
  setQuizQ5,
  setQuizQ6,
} from "../../redux/store";
import { charDetails } from "../../components/quiz/characters";
import { useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const MainContainer = styled.div`
  animation: ${fadeIn} 0.7s ease-in-out;
`;

const Quiz = () => {
  const { character_name, app_completed } = useSelector(
    (state: RootState) => state.application
  );

  // const dispatch = useDispatch();

  if (character_name) {
    const { header, paragraph, imageSrc, imgBackground } =
      charDetails[character_name.toLowerCase() as keyof typeof charDetails];
    return (
      <MainContainer>
        <Results
          header={header}
          paragraph={paragraph}
          imageSrc={imageSrc}
          onRestart={() => {}}
          imgBackground={imgBackground}
        />
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <QuizComponent />
    </MainContainer>
  )

  // return (
  //   <MainContainer>
  //     <Results
  //       header={"The Cat"}
  //       paragraph={"You are a cat."}
  //       imageSrc={"/assets/characters/cat.svg"}
  //       onRestart={() => {}}
  //       imgBackground={"white"}
  //     />
  //   </MainContainer>
  // );
};

export default Quiz;
