import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../input-select";
import FileUpload from "../file-upload";
import {
  setAppSchool,
  setAppMajor,
  setAppYear,
  setAppGithub,
  setAppDiscord,
  setAppLinkedIn,
  setAppOtherLinks,
  RootState,
} from "../../redux/store";
import { ApplicationPage } from "./index.styles";
import Input from "../input-box";
import { yearOptions } from "./dropdown-options/year-of-study";

type AppPage2Props = {
  isSchoolError: boolean;
  isMajorError: boolean;
  isYearError: boolean;
  isYearTextError: boolean;
  isGithubError?: boolean;
  isLinkedInError?: boolean;
  isDiscordError?: boolean;
};

const AppPage2 = React.memo(
  ({
    isSchoolError,
    isMajorError,
    isYearError,
    isYearTextError,
    isGithubError,
    isLinkedInError,
    isDiscordError,
  }: AppPage2Props) => {
    const dispatch = useDispatch();
    const {
      app_page,
      app_school,
      app_major,
      app_year,
      app_github,
      app_linkedIn,
      app_otherLinks,
      app_completed,
      app_discord,
    } = useSelector((state: RootState) => state.application);

    return (
      <ApplicationPage id="page-2" show={app_page == 2}>
        <FileUpload
          text="Upload Resume"
          title="Upload your Resume below:"
          colour="blue"
          disabled={app_completed}
        />
        <Input
          id="discord"
          title="What is your Discord username?"
          type="text"
          errorLabel="Please enter your Discord username."
          placeholder="e.g. “johndoe”"
          value={app_discord}
          isError={isDiscordError}
          onChange={(e) => {
            dispatch(setAppDiscord(e.target.value));
          }}
          requiredStar
          disabled={app_completed}
        />
        <Input
          id="github"
          title="GitHub Profile URL:"
          type="text"
          errorLabel="Please provide a valid GitHub Profile URL."
          placeholder="e.g. “www.github.com/username”"
          value={app_github}
          isError={isGithubError}
          onChange={(e) => {
            dispatch(setAppGithub(e.target.value));
          }}
          disabled={app_completed}
        />
        <Input
          id="linkedIn"
          title="LinkedIn Profile URL:"
          type="text"
          errorLabel="Please provide a valid LinkedIn URL."
          placeholder="e.g. “www.linkedin.com/in/username”"
          value={app_linkedIn}
          isError={isLinkedInError}
          onChange={(e) => {
            dispatch(setAppLinkedIn(e.target.value));
          }}
          disabled={app_completed}
        />
        <Input
          id="other-links"
          title="Any other links (Portfolio, etc.):"
          type="text"
          errorLabel="Please provide a valid URL."
          placeholder="e.g. “myportfolio.com”"
          value={app_otherLinks}
          onChange={(e) => {
            dispatch(setAppOtherLinks(e.target.value));
          }}
          disabled={app_completed}
        />
      </ApplicationPage>
    );
  }
);

export default AppPage2;
