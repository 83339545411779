import React from "react";
import styled from "styled-components";
import Subjectivity from "./subjectivity";
import LoadingCircleComponent from "./loading-component";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import { API_BASE_URL, API_TYPE, API_VERSION } from "../api/apiConfig";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: -4px;
  text-align: left;
  text-shadow: 0px 4px 0px #282828;
  -webkit-text-stroke: 1px #282828;
  justify-content: center;
  padding: 3rem;
`;

const StyledSubjectivity = styled(Subjectivity)`
  padding: 0;
  margin: 0;
`;

const VerifyAPIPage = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const { token } = useParams();

  React.useEffect(() => {
    setIsLoading(true);

    fetch(`${API_BASE_URL}/${API_VERSION}/${API_TYPE.USER}/verify/${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        navigate("/verified");
        setIsLoading(false);
      } else {
        navigate("/error");
        setIsLoading(false);
      }
    });
  }, []);
  return (
    <StyledDiv>
      <LoadingCircleComponent isOpen={isLoading} />
      <StyledSubjectivity>UofTHacks 12</StyledSubjectivity>
    </StyledDiv>
  );
};

export default VerifyAPIPage;
